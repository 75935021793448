// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, get, update, onValue, query, orderByChild, equalTo, set } from "firebase/database";
import { sendPasswordResetEmail, onAuthStateChanged, initializeAuth, browserLocalPersistence } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7deepZwC-sIZRX8CI--U2QQYcFFJamKM",
  authDomain: "test-ouranos-react.firebaseapp.com",
  databaseURL: "https://test-ouranos-react-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "test-ouranos-react",
  storageBucket: "test-ouranos-react.appspot.com",
  messagingSenderId: "225215269446",
  appId: "1:225215269446:web:6c4887dc65beb82266d847",
  measurementId: "G-MMXEE4FSXD"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = initializeAuth(app, { persistence: browserLocalPersistence });
const firestore = getFirestore(app);
const analytics = getAnalytics(app);

export { getFirestore ,getDatabase, analytics, database, auth, firestore, app, ref, get, update, onValue, sendPasswordResetEmail, onAuthStateChanged, query, orderByChild, equalTo, set };
