import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Spinner } from "@nextui-org/react";
import { ArrowLeft2, Translate } from 'iconsax-react';
import { initializeApp } from 'firebase/app';
import { GoogleGenerativeAI } from "@google/generative-ai"; // Import Gemini
import { useTranslation } from 'react-i18next';


// Configuration Firebase
const firebaseConfigOther = {
    apiKey: "AIzaSyARmd6-70E4YD8iPCWrYctvrMidnu-6ypM",
    authDomain: "starmaze-d4663.firebaseapp.com",
    projectId: "starmaze-d4663",
    storageBucket: "starmaze-d4663.appspot.com",
    messagingSenderId: "123385718679",
    appId: "1:123385718679:web:ebe8dc0ee5526ba20086d2",
    measurementId: "G-RGHK0YB1B3"
};

const appOther = initializeApp(firebaseConfigOther, 'other');
const dbOther = getFirestore(appOther);

const ArticleDetail = () => {
    const { title } = useParams();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [translatedText, setTranslatedText] = useState({ title: null, description: null });
    const [isTranslating, setIsTranslating] = useState(false);  // Un seul indicateur pour les deux traductions
    const [isTranslated, setIsTranslated] = useState(false);  // Pour savoir si les traductions sont terminées
    const navigate = useNavigate();
    const [browserLanguage, setBrowserLanguage] = useState('');
    const { t } = useTranslation();


    // Google Generative AI - Gemini configuration
    const genAI = new GoogleGenerativeAI('AIzaSyDZtKO5iGJSusGELWw9-whiRxkCRv4B1IU');
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

    useEffect(() => {
        // Détection de la langue du navigateur
        const language = navigator.language.split('-')[0] || 'en';  // Utiliser seulement 'fr' ou 'en'
        setBrowserLanguage(language);
    }, []);

    // Fetch the article from Firestore
    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const articlesCollection = collection(dbOther, 'articles');
                const articlesSnapshot = await getDocs(articlesCollection);
                const articlesList = articlesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                const foundArticle = articlesList.find(article =>
                    article.title.replace(/\s+/g, '-').toLowerCase() === title
                );
                setArticle(foundArticle || null);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'article:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchArticle();
    }, [title]);

    const handleBackClick = () => navigate(-1);

    const handleTranslate = async () => {
        setIsTranslating(true);
        await handleTranslateTitle();
        await handleTranslateDescription();
        setIsTranslating(false);
        setIsTranslated(true);  // Marquer les traductions comme terminées
    };

    // Translate title with stream (chunking)
    const handleTranslateTitle = async () => {
        try {
            const prompt = `"${article.title}" translated into ${browserLanguage} (1 option)`;
            const result = await model.generateContentStream(prompt);
            let fullTranslatedTitle = '';
            for await (const chunk of result.stream) {
                const chunkText = await chunk.text();
                fullTranslatedTitle += chunkText;
                setTranslatedText(prev => ({ ...prev, title: fullTranslatedTitle }));
            }
        } catch (error) {
            console.error("Erreur lors de la traduction du titre :", error);
            setTranslatedText(prev => ({ ...prev, title: "Erreur lors de la traduction du titre." }));
        }
    };

    // Translate description with stream (chunking)
    const handleTranslateDescription = async () => {
        try {
            const prompt = `${article.description} translated into ${browserLanguage}`;
            const result = await model.generateContentStream(prompt);
            let fullTranslatedDescription = '';
            for await (const chunk of result.stream) {
                const chunkText = await chunk.text();
                fullTranslatedDescription += chunkText;
                setTranslatedText(prev => ({ ...prev, description: fullTranslatedDescription }));
            }
        } catch (error) {
            console.error("Erreur lors de la traduction de la description :", error);
            setTranslatedText(prev => ({ ...prev, description: "Erreur lors de la traduction de la description." }));
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Spinner />
            </div>
        );
    }

    if (!article) return <p>Article introuvable</p>;

    const paragraphs = article.description.split('\n');

    return (
        <div className='text-left m-auto w-full min-h-screen'>
            <button className='my-4' onClick={handleBackClick}>
                <ArrowLeft2 />
            </button>

            <h1 className='text-3xl font-bold'>
                {translatedText.title || article.title}
            </h1>

            <div className='flex justify-between my-2' >
                <h2 >{article.createdAt.toDate().toLocaleDateString(browserLanguage, {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                })}</h2>


                <Button
                    size="sm"
                    variant='flat'
                    onPress={handleTranslate}
                    isDisabled={isTranslating || isTranslated} // Désactiver après traduction
                >
                    <Translate size={18} />
                    {isTranslating ? t('translate_button.in_progress') : (isTranslated ? t('translate_button.completed') : t('translate_button.default'))}
                </Button>
            </div>



            <div className='my-4 flex flex-col gap-2 bg-gray-950 rounded-md p-4'>
                <img src={article.firstImage} alt={article.title} className='md:max-w-xl m-auto rounded' />
                <p className='text-default-600 text-center text-sm'>{article.imageCaption}</p>
            </div>

            <div className="mt-4">
                {translatedText.description ? (
                    <p className='text-white text-lg leading-7 mb-4'>{translatedText.description}</p>
                ) : (
                    paragraphs.map((para, index) => (
                        <p key={index} className='text-white text-lg leading-7 mb-4'>{para}</p>
                    ))
                )}
            </div>

            {/* Section pour afficher les sources */}
            <div className="mt-6">
                
                <div className="mt-4">
                    <h2>Sources</h2>
                    <div dangerouslySetInnerHTML={{ __html: article.moreInfo }} />
                </div>
            </div>
        </div>
    );
};

export default ArticleDetail;
