import React, { useState, useEffect } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import astronomyData from '../assets/astronomy_event.json';
import { useLocationData } from './GlobalContext';
import PremiumNotification from './PremiumNotification';


const AstronomicalEventsComponent = () => {
    const [nextEvents, setNextEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [selectedTag, setSelectedTag] = useState('All');
    const [browserLanguage, setBrowserLanguage] = useState('');
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { subscription } = useLocationData();

    useEffect(() => {
        const language = navigator.language.split('-')[0] || 'en';
        setBrowserLanguage(language);
    }, []);

    useEffect(() => {
        const now = new Date();
        const futureEvents = astronomyData.astronomyEvents.filter(event => new Date(event.date) > now);
        const sortedEvents = futureEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

        setNextEvents(sortedEvents.slice(0, 3)); // Affiche les 3 premiers événements
        setAllEvents(sortedEvents); // Affiche tous les événements (pas de limite ici)
        setFilteredEvents(sortedEvents); // Pour filtrer et afficher tous les événements
    }, []);

    const filterEvents = (tag) => {
        setSelectedTag(tag);
        if (tag === 'All') {
            setFilteredEvents(allEvents);
        } else {
            const regex = new RegExp(`\\b${tag.trim().toLowerCase()}\\b`, 'i');
            const filtered = allEvents.filter(event => regex.test(event.name.toLowerCase()));
            setFilteredEvents(filtered);
        }
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString(browserLanguage, { day: 'numeric', month: 'long', year: 'numeric' });
    };

    const tagClasses = (isSelected) => `px-4 py-1 rounded-lg ${isSelected ? 'bg-indigo-800 text-white' : 'bg-gray-800'}`;

    return (
        <div className='flex flex-col gap-2'>
            {nextEvents.map((event) => (
                <div key={event.date} className='bg-gray-800 rounded-md p-3 text-left'>
                    <p className='font-bold'>
                        {event.name} -{' '}
                        <span className='text-default-500 font-normal'>
                            {formatDate(event.date)}
                        </span>
                    </p>
                </div>
            ))}

            <Button onPress={onOpen} variant='flat'>
                See all events
            </Button>

            <Modal size='xl' scrollBehavior='inside' className='purple-dark' isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>
                                All astronomy events
                            </ModalHeader>
                            <ModalBody>
                                <div className='mb-4'>
                                    <h3 className='mb-2'>Filter by tags</h3>
                                    <div className='flex gap-2'>
                                        <button
                                            className={tagClasses(selectedTag === 'All')}
                                            onClick={() => filterEvents('All')}
                                        >
                                            All
                                        </button>
                                        <button
                                            className={tagClasses(selectedTag === 'Moon')}
                                            onClick={() => filterEvents('Moon')}
                                        >
                                            Moon event
                                        </button>
                                        <button
                                            className={tagClasses(selectedTag === 'Meteor')}
                                            onClick={() => filterEvents('Meteor')}
                                        >
                                            Meteor event
                                        </button>
                                        <button
                                            className={tagClasses(selectedTag === 'Eclipse')}
                                            onClick={() => filterEvents('Eclipse')}
                                        >
                                            Eclipse event
                                        </button>
                                    </div>
                                </div>

                                <div className='flex flex-col gap-4'>
                                    {filteredEvents.length === 0 ? (
                                        <p>No events match the selected filter.</p>
                                    ) : (
                                        filteredEvents.slice(0, subscription !== 1 ? 2 : filteredEvents.length).map((event, index) => (
                                            <div
                                                key={event.date}
                                                className={`p-3 bg-gray-800 rounded-md ${subscription !== 1 && index >= 1 ? 'blur-sm opacity-70' : ''}`}
                                            >
                                                <p className='font-bold'>
                                                    {event.name} -{' '}
                                                    <font className='font-medium text-gray-500'>
                                                        {formatDate(event.date)}
                                                    </font>
                                                </p>
                                                <p>{event.description}</p>

                                            </div>

                                        ))
                                    )}


                                    <PremiumNotification />


                                </div>
                            </ModalBody>
                            <ModalFooter>
                                {/* Footer content */}
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
};

export default AstronomicalEventsComponent;
