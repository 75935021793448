import { useState, useEffect, useCallback } from 'react';

const ApiComponent = ({ children }) => {
  const [openMeteoData, setOpenMeteoData] = useState(null); // État pour Open Meteo
  const [unit, setUnit] = useState(localStorage.getItem('unit') || 'metric'); // Unité pour température et vent
  const [forceReload, setForceReload] = useState(false); // Drapeau pour forcer le rechargement

  const defaultSettings = {
    latitude: 47,
    longitude: -1.5,
    cityName: "Noirmoutier-en-l'Île",
    model: "best_match",
  };

  // Initialiser les valeurs dans localStorage si elles n'existent pas
  Object.entries(defaultSettings).forEach(([key, value]) => {
    if (!localStorage.getItem(key)) {
      localStorage.setItem(key, value);
    }
  });

  // Utiliser les valeurs stockées ou par défaut
  const [latitude, setLatitude] = useState(parseFloat(localStorage.getItem('latitude')));
  const [longitude, setLongitude] = useState(parseFloat(localStorage.getItem('longitude')));
  const [storedModel, setstoredModel] = useState(localStorage.getItem('defaultModel') || 'best_match');



  //////////////////// OPEN METEO API ////////
  const fetchOpenMeteoData = useCallback(async () => {
    setOpenMeteoData(null);

    // Supprimer les données en cache pour forcer un rechargement
    localStorage.removeItem('openMeteoData');
    localStorage.removeItem('openMeteoCacheTimestamp');

    const cachedOpenMeteoData = localStorage.getItem('openMeteoData');
    const cacheTimestamp = localStorage.getItem('openMeteoCacheTimestamp');

    // Vérifie si le cache est encore valide
    if (cachedOpenMeteoData && cacheTimestamp) {
      const now = new Date().getTime();
      const cacheDuration = 60 * 60 * 1000; // Durée du cache (1 heure)
      if (now - cacheTimestamp < cacheDuration) {
        setOpenMeteoData(JSON.parse(cachedOpenMeteoData));
        console.log('Open Meteo Data (from cache):', JSON.parse(cachedOpenMeteoData));
        return;
      }
    }


    // Si pas de données valides en cache, on refait l'appel API
    try {
      // Construire l'URL de l'API Open Meteo en fonction de l'unité
      let openMeteoUrl = `https://customer-api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&hourly=precipitation_probability,dew_point_2m,temperature_2m,relative_humidity_2m,cloud_cover,cloud_cover_low,cloud_cover_mid,cloud_cover_high,wind_speed_10m,wind_direction_10m&daily=sunrise,sunset&timezone=auto&forecast_days=14&&models=${storedModel}&apikey=s8yKtXHBxEzlxk47`;

      if (unit === 'imperial') {
        openMeteoUrl += `&temperature_unit=fahrenheit&wind_speed_unit=mph`;
      }

      const response = await fetch(openMeteoUrl);
      const data = await response.json();
      setOpenMeteoData(data);

      localStorage.setItem('openMeteoData', JSON.stringify(data));
      localStorage.setItem('openMeteoCacheTimestamp', new Date().getTime());
    } catch (error) {
      console.error('Error fetching Open Meteo data:', error);
    }
  }, [latitude, longitude, unit]); // Ajout de `unit` pour que l'appel API se fasse à chaque changement d'unité.

  ///////////////////// END OPEN METEO API //////

  // Effect hook pour détecter les changements dans `latitude` et `longitude` dans le localStorage
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newLatitude = parseFloat(localStorage.getItem('latitude'));
      const newLongitude = parseFloat(localStorage.getItem('longitude'));

      // Vérifie si les valeurs ont changé dans le localStorage
      if (latitude !== newLatitude || longitude !== newLongitude) {
        setLatitude(newLatitude);
        setLongitude(newLongitude);
        setForceReload(true); // Marque qu'il faut recharger les données
      }
    }, 1000); // Vérifie chaque seconde

    return () => {
      clearInterval(intervalId); // nettoie l'intervalle lors du démontage du composant
    };
  }, [latitude, longitude]); // Dépendances à `latitude` et `longitude` pour vérifier si elles changent

  // Vérification du cache au montage du composant ou lors de la mise à jour des coordonnées ou de l'unité
  useEffect(() => {
    const cachedOpenMeteoData = localStorage.getItem('openMeteoData');
    const cacheTimestamp = localStorage.getItem('openMeteoCacheTimestamp');

    if (cachedOpenMeteoData && cacheTimestamp) {
      const now = new Date().getTime();
      const cacheDuration = 60 * 60 * 1000; // Durée du cache (1 heure)
      if (now - cacheTimestamp < cacheDuration) {
        setOpenMeteoData(JSON.parse(cachedOpenMeteoData));
        console.log('Open Meteo Data (from cache):', JSON.parse(cachedOpenMeteoData));
        return;
      }
    }

    // Si pas de cache valide, on force un appel API
    fetchOpenMeteoData();
  }, [latitude, longitude, unit, forceReload, fetchOpenMeteoData]); // Ajout de `unit` dans les dépendances pour forcer l'appel API si l'unité change

  // Si un changement de latitude, longitude ou unité est détecté, forcer le rechargement de l'API
  useEffect(() => {
    if (forceReload) {
      fetchOpenMeteoData(); // Appel de l'API Open Meteo dès que les coordonnées ou l'unité changent
      setForceReload(false); // Réinitialiser le drapeau après l'appel
    }
  }, [forceReload, fetchOpenMeteoData]); // Quand `forceReload` change, faire l'appel API

  return (
    <div>
      {children({ openMeteoData })}
    </div>
  );
};

export default ApiComponent;
