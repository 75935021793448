import React from 'react';
import { Cloud, Drop, Eye, Wind } from "iconsax-react";
import { TbTemperature } from "react-icons/tb";
import { useTranslation } from 'react-i18next';  // Si vous utilisez i18n
import { MdDewPoint } from "react-icons/md";


// Fonction pour obtenir le label de la note
const getLabel = (t, value) => {
    return t({
        5: 'rating.excellent',
        4: 'rating.veryGood',
        3: 'rating.good',
        2: 'rating.average',
        1: 'rating.poor',
    }[value] || '');
};

const CustomTooltip = ({ active, payload, isLight = false }) => {
    const { t } = useTranslation(); // Traduction
    if (!active || !payload || !payload.length) return null;


    // Récupérer la donnée de la première payload
    const data = payload[0].payload;

    // Vérification du contenu des données pour s'assurer qu'on a bien les bonnes informations
    console.log("Data in tooltip:", data);

    // Calculer l'heure en minutes et formatter en HH:mm
    const totalMinutes = Math.round(data.hour * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedTime = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;

    // Récupération de la note
    const noteLabel = getLabel(t, Math.round(payload[0]?.value ?? 0));
    const roundedValue = Math.round((payload[0]?.value ?? 0) * 2) / 2;

    if (isLight) {
        return (
            <div className="text-left bg-gray-800 p-2 rounded-md z-50">
                <p>{formattedTime}</p>
                <div className='flex gap-1 items-end'>
                    <p className="text-white text-2xl font-medium">{roundedValue}</p>
                    <p className="text-white text-md font-medium">{noteLabel}</p>
                </div>
            </div>
        );
    }

    // Détails complets avec les conditions météorologiques
    const details = [
        { label: 'Cloud Cover', value: `${Math.trunc(data.clouds ?? 0)}%`, Icon: Cloud },
        { label: 'Seeing', value: `${Math.trunc(data.seeing ?? 0)}`, Icon: Eye },
        { label: 'Wind', value: `${Math.trunc(data.windSpeed ?? 0)}`, Icon: Wind },
        { label: 'Humidity', value: `${Math.trunc(data.humidity ?? 0)}%`, Icon: Drop },
        { label: 'Temp', value: `${Math.trunc(data.temperature ?? 0)}°`, Icon: TbTemperature },
        { label: 'Dew point', value: `${Math.trunc(data.dew_point_2m ?? 0)}°`, Icon: MdDewPoint },
    ];

    return (
        <div className="text-left flex flex-col gap-2 bg-gray-800 p-2 rounded-md z-50">
            <p className="text-gray-400">{formattedTime}</p>
            <div className='flex gap-1 items-end'>
                <p className="text-white text-3xl font-medium">{roundedValue}</p>
                <p className="text-white text-xl font-medium">{noteLabel}</p>
            </div>
            <div className="flex flex-col gap-1">
                {details.map(({ label, value, Icon }, index) => (
                    <div key={index} className="flex bg-gray-700 rounded-md p-2 py-1 items-center gap-1 justify-between">
                        <div className="flex items-center gap-1">
                            <Icon size={16} />
                            <p className="text-sm">{label}</p>
                        </div>
                        <span className="font-bold text-white">{value}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};




export default CustomTooltip;
