export const getFirebaseErrorMessage = (error) => {
    const errorCode = error.code;
    switch (errorCode) {
        case "auth/user-not-found":
            return "No user found with this email. Please sign up first.";
        case "auth/wrong-password":
            return "Incorrect password. Please try again.";
        case "auth/invalid-email":
            return "The email address is invalid. Please check and try again.";
        case "auth/user-disabled":
            return "This user account has been disabled.";
        case "auth/too-many-requests":
            return "Too many login attempts. Please try again later.";
        case "auth/invalid-login-credentials":
            return "Invalid login credentials. Please check your email and password.";
        case "auth/email-already-in-use":
            return "An account already exists with this email address. Please try logging in.";
        default:
            return "An unknown error occurred. Please try again.";
    }
};
