export function calculateSkyQualityIndex(cloudCover, humidity, windSpeed, temperature, dewPoint, seeing) {

    // Pré-calculs pour éviter les appels multiples à Math.abs()
    const temperatureDifference = Math.abs(temperature - 15);
    const dewPointDifference = Math.abs(temperature - dewPoint);

    // Calcul des facteurs
    const CloudsFactor = 1 - (cloudCover / 15);
    const HumidityFactor = 5 - (humidity / 20);
    const WindFactor = 5 - (windSpeed / 10);
    const TemperatureFactor = 5 - (temperatureDifference / 10);
    const DewPointFactor = 5 - (dewPointDifference / 5);
    const SeeingFactor = 5 - seeing;  // Inclure le facteur de seeing dans le calcul (plus bas = meilleur seeing)

    // Calcul final de l'indice de qualité du ciel avec les poids attribués
    const SkyQualityIndex = (
        0.5 * CloudsFactor +
        0.2 * HumidityFactor +
        0.2 * WindFactor +
        0.1 * TemperatureFactor +
        0.15 * DewPointFactor +
        0.5 * SeeingFactor  // Poids pour le Seeing
    );

    // Limiter l'indice dans la plage [0, 5] pour assurer qu'il reste dans un intervalle acceptable
    return Math.max(0, Math.min(5, SkyQualityIndex));
}
