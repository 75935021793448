import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase"; // Assurez-vous que l'instance de firestore est bien importée
import { Button } from "@nextui-org/react";
import { Add } from "iconsax-react";


const Notification = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [lastUpdateString, setLastUpdateString] = useState('');
    const [notification, setNotification] = useState({ message: "", link: "", timestamp: null });

    useEffect(() => {
        const checkForNotificationUpdate = async () => {
            try {
                // Vérifiez si la clé last_notification est présente dans le localStorage
                const storedNotificationDate = localStorage.getItem('last_notification');

                const notificationDocRef = doc(firestore, "system", "alert");
                const notificationDocSnapshot = await getDoc(notificationDocRef);

                if (notificationDocSnapshot.exists()) {
                    const data = notificationDocSnapshot.data();
                    const lastUpdate = data.timestamp;

                    // Convert Firestore Timestamp to ISO string
                    const lastUpdateString = lastUpdate && lastUpdate.toDate().toISOString();

                    if (lastUpdateString) {
                        // Si c'est la première fois ou si la date est différente, affiche la notification
                        if (!storedNotificationDate || storedNotificationDate !== lastUpdateString) {
                            setNotification({ message: data.message, link: data.link, timestamp: lastUpdate });
                            setIsBannerVisible(true);
                            setLastUpdateString(lastUpdateString);
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching notification from Firestore:", error);
            }
        };

        checkForNotificationUpdate(); // Check for notification update on component mount
    }, []);

    // Fonction pour masquer la notification et mettre à jour la clé last_notification dans le localStorage
    const handleClose = () => {
        localStorage.setItem('last_notification', lastUpdateString); // Stocke la date actuelle de la notification dans le localStorage
        setIsBannerVisible(false);
    };

    const handleRedirect = () => {
        if (notification.link) {
            window.open(notification.link, '_blank');
        }
        handleClose();
    };

    return (
        <>
            {isBannerVisible && (
                <>
                    <div
                    
                       
                        className='z-50 cursor-pointer purple-dark rounded-none w-full bg-amber-300 py-2 md:px-80 px-4 flex justify-between items-center'>
                        <p     onClick={handleRedirect} className="font-medium text-black " >
                            {notification.message}
                        </p>

                        <button onClick={handleClose} ><Add size={30} color="black" className="rotate-45" /></button>
                    </div>

                </>
            )}
        </>
    );
};

export default Notification;
