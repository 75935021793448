// Tab1.js
import React, { useState, useEffect } from 'react';
import TonightComponent from './TonightComponent';
import ApiComponent from './ApiComponent';
import LocationComponent from './LocationComponent';
import HourForecast from './HourForecast';


const Tab1 = () => {


  const [lastUpdated, setLastUpdated] = useState(null); // État pour stocker la date de mise à jour

  useEffect(() => {

    // Récupérer et afficher le timestamp de la dernière mise à jour
    const cacheTimestamp = localStorage.getItem('openMeteoCacheTimestamp');
    if (cacheTimestamp) {
      // Détecter la langue du navigateur
      const browserLanguage = navigator.language || navigator.userLanguage;

      // Formater l'heure selon la langue du navigateur
      const formattedTime = new Date(parseInt(cacheTimestamp)).toLocaleTimeString(browserLanguage, {
        hour: '2-digit',   // Afficher l'heure sur 2 chiffres
        minute: '2-digit'  // Afficher les minutes sur 2 chiffres
      });

      setLastUpdated(formattedTime); // Stocke l'heure formatée sans les secondes
    }
  }, []);

  return (
    <div>
      <LocationComponent />

      <ApiComponent>
        {({ openMeteoData }) => (
          <TonightComponent openMeteoData={openMeteoData} />
        )}
      </ApiComponent>


      <ApiComponent>
        {({ openMeteoData }) => (
          <HourForecast openMeteoData={openMeteoData} />
        )}
      </ApiComponent>
    </div>
  );
};

export default Tab1;