import React, { useState } from 'react';
import { auth, sendPasswordResetEmail } from '../firebase';
import { signInWithEmailAndPassword} from "firebase/auth";
import Signup from "./Signup";
import Logo from '../assets/logo-header.svg'
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import { Button, Input, Alert } from "@nextui-org/react";
import { useLocationData } from './GlobalContext';

import { getFirebaseErrorMessage } from "./firebaseErrors"; // Ton fichier de gestion des erreurs


const Tab4 = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { user, subscription, userData } = useLocationData();
  const [isButtonDisabledManageBtn, setButtonDisabledManageBtn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSignup, setShowSignup] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: "", color: "default" });


  const showAlert = (message, color) => {
    setAlert({ visible: true, message, color });
    setTimeout(() => setAlert({ visible: false, message: "", color: "default" }), 5000);
  };

  const handleDeleteAccount = () => {
    if (window.confirm("Are you sure you want to delete your account and all your data? This action is irreversible.")) {
      user.delete().then(function () {
        console.log("User deleted.");
      }).catch(function (error) {
        console.error("Error deleting user:", error);
      });
    }
  };


  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = getFirebaseErrorMessage(error);

        showAlert(errorMessage, "danger");

        console.error("Error during sign in:", error);
        setLoading(false);
      });
  };



  const handleSignOut = () => {
    auth.signOut();
  };

  const sendResetEmail = async () => {
    if (!email) {
      showAlert("Please enter your email address.", "warning");
      return;
    }

    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      showAlert("Check your inbox for the password reset email.", "success");
      setLoading(false);
    } catch (error) {
      const errorMessage = getFirebaseErrorMessage(error);
      showAlert(errorMessage, "info");
      console.error("Error sending password reset email:", error);
      setLoading(false);
    }
  };


  const handleSignup = () => {
    setShowSignup(true);
  };

  const handleBackToLogin = () => {
    setShowSignup(false);
  };

  const handleCustomerPortalButtonClick = async () => {
    setLoading(true);
    setButtonDisabledManageBtn(true);
    if (userData?.stripeCustomerId) {
      try {
        const functionUrl = 'https://us-central1-test-ouranos-react.cloudfunctions.net/createCustomerPortail-createCustomerPortail'; // Remplacez par l'URL de votre fonction Firebase

        const response = await fetch(functionUrl, {
          method: 'POST',
          mode: 'cors', // Ajoutez cette option
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customerId: userData?.stripeCustomerId }),
        });

        if (response.ok) {
          const session = await response.json();
          await Browser.open({ url: session.url });
          setLoading(false);
        } else {
          console.error('Une erreur s\'est produite lors de la création de la session.');
        }
      } catch (error) {
        console.error('Une erreur s\'est produite lors de la requête.', error);
      }
    } else {
      setLoading(false);
      console.error('customerId is empty.');
      alert("Subscribe first to manage your subscription.")

    }
  };


  if (showSignup) {
    return <Signup onBackToLogin={handleBackToLogin} />;
  }


  if (user) {
    return (

      <div className='min-h-screen pt-8 flex flex-col gap-2 text-left' >



        <h1>{t('helloUser')}, {userData?.username}</h1>


        <p>
          {t('mySubscription', { subscription: subscription === 1 ? 'Premium' : 'Freemium' })}
        </p>





        <div className='flex flex-col sm:grid sm:grid-cols-3 gap-2 mt-2 ' >
          <Button onPress={handleCustomerPortalButtonClick} disabled={loading}>
            {loading ? (
              <div className="loaderButton" />
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '6px' }} >
                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18">
                  <path fill='var(--white)' d="M182.152-114.022q-27.599 0-47.865-20.265-20.265-20.266-20.265-47.865v-595.696q0-27.697 20.265-48.033 20.266-20.337 47.865-20.337h242.783q14.424 0 24.244 10.012Q459-826.194 459-811.717q0 14.478-9.821 24.174-9.82 9.695-24.244 9.695H182.152v595.696h595.696v-242.783q0-14.424 9.871-24.244Q797.59-459 812.068-459q14.477 0 24.313 9.821 9.837 9.82 9.837 24.244v242.783q0 27.599-20.337 47.865-20.336 20.265-48.033 20.265H182.152Zm181.609-250q-9.326-9.804-9.826-23.385-.5-13.581 9.695-23.963l366.718-366.478H553.065q-14.424 0-24.244-9.871Q519-797.59 519-812.068q0-14.477 9.821-24.313 9.82-9.837 24.244-9.837h258.848q14.394 0 24.349 9.956 9.956 9.955 9.956 24.349v258.848q0 14.424-10.012 24.244Q826.194-519 811.717-519q-14.478 0-24.174-9.821-9.695-9.82-9.695-24.244v-176.283L411.37-362.63q-9.638 9.195-23.591 9.076-13.953-.12-24.018-10.468Z" />
                </svg>
                {t('manageBilling')}
              </div>
            )}
          </Button>


          <Button onPress={handleSignOut}>{t('signOutButton')}</Button>

          <Button color='danger' onPress={handleDeleteAccount}>{t('deleteAccountButton')}</Button>
        </div>

      </div>
    );
  } else {
    return (
      <div>



        <div className='min-h-screen ' >
          <form className="flex flex-col gap-4 m-auto md:w-1/2 " onSubmit={signIn}>

            <img style={{ width: '12em', margin: 'auto', paddingBlock: '2em' }} src={Logo} alt="Logo Ouranos" />
            <h1>{t("login")}</h1>

            <Input variant='faded' size='lg' label={t('emailLabel')} autoComplete="email" type="email" placeholder="example@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            <Input variant='faded' size='lg' label={t('passwordLabel')} autoComplete="current-password" type="password" placeholder={t("passwordPlaceholder")} value={password} onChange={(e) => setPassword(e.target.value)} />
            
            {alert.visible && (
              <Alert  color={alert.color}  title={alert.message} className="my-4" />
            )}

            <div >
              <p>Forgot your password?
                <a href="#" onClick={sendResetEmail} style={{ textDecoration: 'underline' }}>
                  {t("resetpassword")}
                </a>
              </p>
            </div>

            <div className='flex flex-col gap-2' >
              <Button color='primary' size='lg' className={loading ? "btn-primary-loading w-full" : "btn-primary w-full"} type="submit" disabled={loading}>
                {loading ? <div className="loaderLogin" /> : t("login")}
              </Button>
              <p>{t('or')}</p>

              <Button size='lg' className=" w-full" type="button" onPress={handleSignup}>{t("signup")}</Button>
            </div>

          </form>
        </div>


      </div>
    );
  }
};

export default Tab4;
