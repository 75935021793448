import React from 'react';
import { FilloutStandardEmbed } from "@fillout/react";
import "@fillout/react/style.css";

const AccessRequestPage = () => {
    return (
        <div className='min-h-screen text-left'>
            <h1 className='text-2xl font-bold'>Request Access to the Ouranos API</h1>
            <p className='mt-4 '>
                To access the Ouranos API and get information such as the observation indicator, sky quality index, seeing, cloud cover, and all other data, please contact me through the form.
            </p>
            <div
                style={{
             
                    height: 600,
                }}
            >
                <FilloutStandardEmbed filloutId="s1cG1mXeSpus" />
            </div>
        </div>
    );
};

export default AccessRequestPage;
