import React from 'react';
import { Button } from "@nextui-org/react";
import { Lock } from "iconsax-react";
import { useNavigate } from 'react-router-dom'; // Assurez-vous d'avoir react-router-dom installé


const PremiumNotification = () => {

    const navigate = useNavigate();

    // Fonction qui sera appelée lors du clic sur le bouton
    const handleSubscribe = () => {
        // Logique de navigation (par exemple, rediriger vers la page d'abonnement)
        navigate('/dailyforecast'); // Remplace par l'URL de la page d'abonnement
    };

    return (
        <div className="flex justify-center gap-2 items-center bg-gray-900 text-white p-2 px-4 rounded-md text-center z-50 ">
            <p className='bg-yellow-100 text-yellow-950 uppercase text-sm font-bold px-2 rounded-md ' >Premium</p>
            <p className="text-sm"> version required</p>
            <Button color='primary' onPress={handleSubscribe} size="sm">
                Subscribe
            </Button>
        </div>
    );
};

export default PremiumNotification;
