import React from 'react';
import { FiSunset, FiSunrise } from "react-icons/fi";
import { CloudDrizzle, Clock, Cloud, Drop, Eye, Wind, Timer1, Diagram } from "iconsax-react";
import { TbTemperature } from "react-icons/tb";
import { MdDewPoint } from "react-icons/md";
import { useTranslation } from 'react-i18next';

const HourlyForecastComponent = ({
    data,
    isFavorableCondition,
}) => {
    const now = new Date();
    const { t } = useTranslation();

    return (
        <div className="bg-gray-900 rounded-md ">
            <div className="sticky top-0 bg-gray-900 rounded-md">
                {/* Titre */}
                <div className="p-3 flex gap-1 items-center">
                    <Timer1 size={16} className="text-gray-500" />
                    <h2>{t("hourlyforecast")}</h2>
                </div>

                {/* En-têtes */}
                <div
                    className="sticky top-0 bg-gray-800 FlexClass py-2"
                    style={{
                        justifyContent: "space-around",
                        borderBottom: "1px solid var(--grey20)",
                    }}
                >
                    {[
                        <Clock size={16} className="text-gray-400" />,
                        <Cloud size={16} className="text-gray-400" />,
                        <CloudDrizzle size={16} className="text-gray-400" />,
                        <Eye size={16} className="text-gray-400" />,
                        <Wind size={16} className="text-gray-400" />,
                        <Drop size={16} className="text-gray-400" />,
                        <TbTemperature size={16} className="text-gray-400" />,
                        <MdDewPoint size={16} className="text-gray-400" />,
                    ].map((icon, index) => (
                        <div key={index} className="flex items-center justify-center w-16">
                            {icon}
                        </div>
                    ))}
                </div>
            </div>

            {/* Données horaires */}
            {data.map((hourlyData, index) => {
                const hourlyDate = new Date(hourlyData.timestamp || hourlyData.dateTime);
                const sunriseTime = new Date(hourlyData.sunrise);
                const sunsetTime = new Date(hourlyData.sunset);

                // Déterminer si on affiche un titre pour un nouveau jour
                const isMidnight = hourlyDate.getHours() === 0;
                const isNewDay =
                    (index === 0 && hourlyDate.getDate() !== now.getDate()) || isMidnight;
                const dayHeader = isNewDay && (
                    <h2 className="text-gray-400 bg-gray-900 text-center py-2">
                        {hourlyDate.toLocaleDateString(navigator.language, { weekday: "long" })}
                    </h2>
                );

                // Gestion des événements lever/coucher du soleil et nuit astronomique
                let sunriseSunsetRow = null;
                if (hourlyDate.getHours() === sunriseTime.getHours()) {
                    sunriseSunsetRow = (
                        <div className="bg-gray-950 flex justify-center gap-2 py-1 items-center">
                            <FiSunrise size={16} className="text-amber-200" />
                            <p>
                                Sunrise at{" "}
                                {sunriseTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                            </p>
                        </div>
                    );
                } else if (hourlyDate.getHours() === sunsetTime.getHours()) {
                    sunriseSunsetRow = (
                        <div className="bg-gray-950 flex justify-center gap-2 py-1 items-center">
                            <FiSunset size={16} className="text-amber-200" />
                            <p>
                                Sunset at{" "}
                                {sunsetTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                            </p>
                        </div>
                    );
                }

                // Vérifier les conditions favorables
                const favorable = isFavorableCondition(hourlyData);

                return (
                    <React.Fragment key={index}>
                        {dayHeader}
                        <div
                            className={`${favorable
                                ? "bg-teal-950 hover:bg-teal-900 font-medium border-x-2 border-teal-600"
                                : "hover:bg-gray-800 border-b border-gray-800"
                                } py-4 md:py-2 ContainerHour`}
                        >
                            <div className={`text-center w-16 ${favorable ? "text-teal-200" : "text-default-500"}`}>
                                {hourlyDate.getHours()}h
                            </div>
                            <div className={`text-center w-16 ${favorable ? "text-teal-200" : ""}`}>
                                {hourlyData.clouds}%
                            </div>
                            <div className="text-center w-16">{hourlyData.precipitation_probability}%</div>
                            <div className="text-center w-16">{hourlyData.seeing}</div>
                            <div className="text-center w-16">{Math.trunc(hourlyData.windSpeed)}</div>
                            <div className="text-center w-16">{hourlyData.humidity}%</div>
                            <div className="text-center w-16">{Math.trunc(hourlyData.temperature)}°</div>
                            <div className="text-center w-16">{Math.trunc(hourlyData.dew_point_2m)}°</div>
                        </div>
                        {sunriseSunsetRow}
                    </React.Fragment>
                );
            })}
        </div>
    );
};


export default HourlyForecastComponent;