import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="text-left container mx-auto ">
            <h1 className="text-3xl font-bold  mb-6 ">Privacy Policy</h1>
            <p className="text-sm   mb-6">
                <strong>Effective Date:</strong> 03/12/2024 | <strong>Last Updated:</strong> 03/12/2024
            </p>

            <p className="mb-4">
                Welcome to Ouranos! Protecting your data is our priority. This Privacy Policy explains how we
                collect, use, and protect the information when you use our services.
            </p>

            <section className="mb-8">
                <h1 className="text-xl font-semibold  mb-3">1. Information We Collect</h1>
                <p className="mb-3">We only collect the following information:</p>
                <ul className="list-disc list-inside space-y-2">
                    <li><strong>Email:</strong> To create your account and communicate with you.</li>
                    <li><strong>Username:</strong> Chosen by you during account creation.</li>
                    <li><strong>Favorite Locations:</strong> To save your preferred observation spots.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold getHours mb-3">2. How We Use Your Information</h1>
                <p className="mb-3">The information collected is used solely for the following purposes:</p>
                <ul className="list-disc list-inside space-y-2">
                    <li>Managing your user account on Ouranos.</li>
                    <li>Providing personalized weather and astronomical forecasts.</li>
                    <li>Saving and retrieving your favorite locations for an optimized experience.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold getHours mb-3">3. Sharing Your Information</h1>
                <p className="mb-3">
                    Ouranos does not sell or share your personal information with third parties, except in the
                    following cases:
                </p>
                <ul className="list-disc list-inside space-y-2">
                    <li><strong>Legal Compliance:</strong> If required to comply with legal obligations.</li>
                    <li><strong>Service Providers:</strong> To perform essential functions like website hosting and data storage using Google Firebase.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold getHours mb-3">4. Data Security</h1>
                <p>
                    We use standard security measures to protect your information, including the infrastructure provided by Google Firebase.
                    However, no transmission or storage method is completely secure, and we cannot guarantee absolute security.
                </p>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold getHours mb-3">5. Data Retention</h1>
                <p>
                    Your data is retained as long as your account is active. If you delete your account, all data,
                    including favorite locations, will be permanently deleted unless retention is required by law.
                </p>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold getHours mb-3">6. Your Rights</h1>
                <p className="mb-3">Under applicable regulations, you may have the following rights:</p>
                <ul className="list-disc list-inside space-y-2">
                    <li>Access your personal data.</li>
                    <li>Correct or delete your information.</li>
                    <li>Withdraw your consent at any time.</li>
                </ul>
                <p>
                    To exercise these rights, contact us at{' '}
                    <a href="mailto:contact@ouranos-app.com" className="text-blue-600 underline">contact@ouranos-app.com</a>.
                </p>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold getHours mb-3">7. Updates to This Policy</h1>
                <p>
                    We may update this Privacy Policy to reflect legal or operational changes. Significant changes
                    will be communicated via email or on our platform.
                </p>
            </section>

            <section>
                <h1 className="text-xl font-semibold getHours mb-3">8. Contact Us</h1>
                <p className="mb-3">If you have any questions about this Privacy Policy, please contact us:</p>
                <ul className="list-none space-y-2">
                    <li><strong>Email:</strong> <a href="mailto:contact@ouranos-app.com" className="text-blue-600 underline">contact@ouranos-app.com</a></li>
                </ul>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
