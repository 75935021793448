import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Skeleton, Spinner } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import AstronomicalEventsComponent from './AstronomicalEventsComponent'

// Configuration de l'autre projet Firebase (le seul que vous utilisez)
const firebaseConfigOther = {
    apiKey: "AIzaSyARmd6-70E4YD8iPCWrYctvrMidnu-6ypM",
    authDomain: "starmaze-d4663.firebaseapp.com",
    projectId: "starmaze-d4663",
    storageBucket: "starmaze-d4663.appspot.com",
    messagingSenderId: "123385718679",
    appId: "1:123385718679:web:ebe8dc0ee5526ba20086d2",
    measurementId: "G-RGHK0YB1B3"
};

// Initialisation de Firebase pour l'autre projet
const appOther = initializeApp(firebaseConfigOther, 'other');
const dbOther = getFirestore(appOther);



const Blog = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [browserLanguage, setBrowserLanguage] = useState('');
    const [limitCount, setLimitCount] = useState(6); // Définir la limite initiale
    const [articles, setArticles] = useState([]); // Stocker les articles
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true); // Gérer la fin des articles

    useEffect(() => {
        const fetchArticles = async () => {
            setLoading(true);
            try {
                const q = query(
                    collection(dbOther, 'articles'),
                    orderBy('createdAt', 'desc'),
                    limit(limitCount)
                );
                const snapshot = await getDocs(q);
                const newArticles = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setArticles(newArticles);
                setHasMore(newArticles.length >= limitCount); // S'il y a moins d'articles que la limite, on est à la fin
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, [limitCount]);

    const loadMore = () => {
        if (hasMore && !loading) {
            setLimitCount(prev => prev + 6); // Charger 6 articles supplémentaires
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + window.scrollY >= document.body.offsetHeight - 200 &&
                hasMore &&
                !loading
            ) {
                loadMore();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore, loading]);

    useEffect(() => {
        // Détection de la langue du navigateur
        const language = navigator.language.split('-')[0] || 'en';
        setBrowserLanguage(language);
    }, []);

    const handleArticleClick = (title) => {
        const slugifiedTitle = title.replace(/\s+/g, '-').toLowerCase();
        navigate(`/news/${slugifiedTitle}`);
    };

    const formatDate = (date) => {
        return date.toLocaleDateString(browserLanguage, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };

    if (error) {
        return <p className="text-red-500">Erreur : {error}</p>;
    }

    return (
        <div className='pt-8 min-h-screen flex flex-col '>
            <h1>{t('news')}</h1>

            <div>
                <h2 className='my-2'>Next astronomical event</h2>
                <AstronomicalEventsComponent />
            </div>

            <div>
                <h2 className='my-2 mt-6'>Last astronomy news</h2>
                <div className='flex flex-col gap-4'>
                    {articles.length > 0 ? (
                        articles.map(article => (
                            <div
                                key={article.id}
                                className='md:hover:bg-gray-800 text-left p-4 rounded-lg bg-default-50 cursor-pointer'
                                onClick={() => handleArticleClick(article.title)}
                            >
                                <div className='flex flex-col sm:flex-row gap-4'>
                                    <div>
                                        <img
                                            src={article.firstImage || '/default-image.png'}
                                            alt={article.title}
                                            className='m-auto object-cover max-w-56 rounded'
                                        />
                                    </div>

                                    <div>
                                        <h1 className='text-xl my-2'>{article.title}</h1>

                                        <div className='flex flex-col gap-2'>
                                            <p className='text-default-700 line-clamp-2'>{article.description}</p>
                                            <h2>
                                                {formatDate(article.createdAt.toDate())}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                       <></>
                    )}
                    {loading && <Spinner />}
                </div>
            </div>
        </div>
    );
};


export default Blog;
