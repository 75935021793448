import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="bg-gray-950 text-gray-400 py-6 border-t-1 border-gray-800 pb-24">
            <div className="container mx-auto px-4">
                <div className="flex flex-col items-center flex-row  space-y-6 md:space-y-0">
                    {/* Links Section */}
                    <ul className="flex flex-col space-y-4 items-center ">
                        <li>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://amusing-attic-3a5.notion.site/Enchant-0db60261f1f044be96b27cb8beaef124?pvs=4"
                                className="hover:text-white"
                            >
                                {t("aboutus")}
                            </a>
                        </li>
                        <li>
                            <a
                                href="mailto:contact@ouranos-app.com"
                                className="hover:text-white"
                            >
                                {t("contactus")}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://ouranos-shop.myspreadshop.fr/"
                                className="hover:text-white"
                            >
                                Shop
                            </a>
                        </li>

                        <Link to="/api_info" className="hover:text-white">
                            API
                        </Link>


                        <li>
                            <Link to="/privacy" className="hover:text-white">
                                {t("privacypolicy")}
                            </Link>
                        </li>
                        <li>
                            <Link to="/terms" className="hover:text-white">
                                {t("terms")}
                            </Link>
                        </li>
                    </ul>

                    {/* Badges Section */}
                    <div className="flex flex-col items-center ">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.ouranos_app.www.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        >
                            <img
                                className="w-48"
                                alt="Get it on Google Play"
                                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            />
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://apps.apple.com/us/app/ouranos-weather-astronomy/id6456041564?itsct=apps_box_badge&amp;itscg=30200"
                        >
                            <img
                                className="w-40"
                                alt="Download on the App Store"
                                src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg"
                            />
                        </a>
                    </div>
                </div>

                {/* Footer Bottom */}
                <div className="mt-6 text-center">
                    <p className="text-sm">
                        © 2024 Paul Joly, {t("<3")} {t("all rights reserved")}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
