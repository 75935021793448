import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import tickImage from '../assets/tick.svg';
import nopImage from '../assets/nop.svg';
import cloud20 from '../assets/cloud/20.svg';
import cloud50 from '../assets/cloud/50.svg';
import cloud80 from '../assets/cloud/80.svg';
import arrow from '../assets/near_me_FILL1_wght400_GRAD0_opsz48.svg';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { calculateSeeingIndex } from './SeeingCalculator';
import { InfoCircle, Eye, Cloud, Wind, CloudDrizzle, Drop } from "iconsax-react";
import { Divider, Tooltip } from "@nextui-org/react";
import LightPollutionMap from './LightPollutionMap';
import Survey from './Survey'; // Importation du composant Survey
import { TbTemperature } from "react-icons/tb";
import { MdDewPoint } from "react-icons/md";
import CloudLevelInfo from "./CloudLevelInfo";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";
import MoonComponent from './MoonComponent';


const goodCloudCoverThreshold = 30;
const consecutiveGoodHoursRequired = 2;

const TonightComponent = ({ openMeteoData }) => {
    const { t } = useTranslation();
    const [latitude, setLatitude] = useState(parseFloat(localStorage.getItem('latitude')) || 0);
    const [longitude, setLongitude] = useState(parseFloat(localStorage.getItem('longitude')) || 0);
    const [unitTemp, setUnitTemp] = useState(localStorage.getItem('tempUnit') || '°C');
    const [unitWind, setUnitWind] = useState(localStorage.getItem('windUnit') || 'km/h');
    const [stargazingDescription, setStargazingDescription] = useState('');
    const [isGenerated, setIsGenerated] = useState(false); // Track if the content is fully generated
    const [isLoading, setIsLoading] = useState(true);  // Track loading state

    const [lastFetchedTime, setLastFetchedTime] = useState(parseInt(localStorage.getItem('lastFetchedTime'), 10) || 0);
    const [browserLanguage, setBrowserLanguage] = useState('');
    const [ShowInfoSeeing, setShowInfoSeeing] = useState(false);
    const [ShowInfoWind, setShowInfoWind] = useState(false);


    useEffect(() => {
        // Detect the browser's language
        const language = navigator.language || 'en';  // Default to 'en' if not available
        setBrowserLanguage(language);
        //console.log('Browser language:', language); // You can log it to check
    }, []);

    // Générer un modèle Gemini
    const genAI = new GoogleGenerativeAI('AIzaSyDZtKO5iGJSusGELWw9-whiRxkCRv4B1IU');
    const model = genAI.getGenerativeModel({
        model: "gemini-1.5-flash",
    });

    const handleShowInfoWind = () => {
        setShowInfoWind(!ShowInfoWind);
    };

    const handleShowInfoSeeing = () => {
        setShowInfoSeeing(!ShowInfoSeeing);
    };

    useEffect(() => {
        const handleStorageChange = () => {
            setLatitude(parseFloat(localStorage.getItem('latitude')) || 0);
            setLongitude(parseFloat(localStorage.getItem('longitude')) || 0);
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const generateForecastData = () => {
        if (!openMeteoData || !openMeteoData.hourly || !Array.isArray(openMeteoData.hourly.time)) {
            ////console.log("No hourly time data found");
            return [];
        }

        // Log the entire hourly data object for a global view
        // //console.log("Full openMeteoData.hourly:", openMeteoData.hourly);

        return openMeteoData.hourly.time.map((time, index) => ({
            dateTime: new Date(time),
            hour: new Date(time).getHours(),
            clouds: openMeteoData.hourly.cloud_cover?.[index] || 0,

            clouds_low: openMeteoData.hourly.cloud_cover_low?.[index] || 0,
            clouds_mid: openMeteoData.hourly.cloud_cover_mid?.[index] || 0,
            clouds_high: openMeteoData.hourly.cloud_cover_high?.[index] || 0,

            temperature: openMeteoData.hourly.temperature_2m?.[index] || null,
            windSpeed: openMeteoData.hourly.wind_speed_10m?.[index] || null,
            windDirection: openMeteoData.hourly.wind_direction_10m?.[index] || null,
            humidity: openMeteoData.hourly.relative_humidity_2m?.[index] || null,
            dew_point_2m: openMeteoData.hourly.dew_point_2m?.[index] || null,
            precipitation_probability: openMeteoData.hourly.precipitation_probability?.[index] || null,
        }));
    };



    const forecastData = useMemo(generateForecastData, [openMeteoData]);

    // Define sunset and sunrise times for tonight's astronomical night
    const currentDate = new Date();
    let sunsetTime = new Date(openMeteoData?.daily?.sunset[0]);
    sunsetTime.setHours(sunsetTime.getHours() + 2);  // Sunset +1 hour for astronomical night

    let sunriseTime = new Date(openMeteoData?.daily?.sunrise[1]);
    sunriseTime.setHours(sunriseTime.getHours() - 2); // Sunrise -1 hour for end of astronomical night

    // Gestion des fuseaux horaires
    const offsetHours = currentDate.getTimezoneOffset() / 60;

    // Heures de coucher et lever du soleil pour la nuit actuelle
    let tonightSunset = new Date(openMeteoData?.daily?.sunset[0]); // Coucher du soleil ce soir
    tonightSunset.setHours(tonightSunset.getHours() + 2 - offsetHours); // Ajustement astronomique

    let tonightSunrise = new Date(openMeteoData?.daily?.sunrise[1]); // Lever du soleil demain matin
    tonightSunrise.setHours(tonightSunrise.getHours() - 2 - offsetHours); // Ajustement astronomique


    // Check if we are currently in tonight’s astronomical night
    if (currentDate > sunsetTime && currentDate < sunriseTime) {
        // Use tonight's sunset and sunrise if we are already in the night
        sunsetTime = new Date(openMeteoData.daily.sunset[0]);
        sunriseTime = new Date(openMeteoData.daily.sunrise[1]);
    }

    // Filter forecast data for tonight's astronomical night
    const nightForecastData = forecastData.filter(({ dateTime }) =>
        dateTime >= sunsetTime && dateTime <= sunriseTime

    );


    // Find the best observation time range within tonight's astronomical night
    const getBestObservationTimeRange = (data) => {
        let observationStartTime = null;
        let observationEndTime = null;
        let consecutiveGoodHours = 0;
        let lowestCloudCover = Infinity;
        let extendedEndTime = null;

        for (let i = 0; i < data.length; i++) {
            const hourlyData = data[i];
            const { hour, clouds } = hourlyData;

            if (clouds <= goodCloudCoverThreshold) {
                if (consecutiveGoodHours === 0) observationStartTime = hour;
                consecutiveGoodHours++;
                lowestCloudCover = Math.min(lowestCloudCover, clouds);
                extendedEndTime = hour;

                if (consecutiveGoodHours >= consecutiveGoodHoursRequired) {
                    observationEndTime = extendedEndTime;
                }
            } else {
                if (observationEndTime) break; // Stop extending if minimum is met and conditions change
                consecutiveGoodHours = 0;
                observationStartTime = null;
                observationEndTime = null;
                lowestCloudCover = Infinity;
            }
        }

        return {
            timeRange: observationStartTime && observationEndTime
                ? { start: observationStartTime, end: observationEndTime }
                : null,
            lowestCloudCover: lowestCloudCover === Infinity ? null : lowestCloudCover,
        };
    };

    const bestObservationInfo = useMemo(() => getBestObservationTimeRange(nightForecastData), [nightForecastData]);


    const generateObservationText = (nightForecastData) => {
        //console.log('nightForecastData', nightForecastData)
        //console.log('generation description Gemini')
        // Vérifier si nightForecastData existe et est un tableau
        if (!nightForecastData || !Array.isArray(nightForecastData)) {
            console.error("nightForecastData is undefined, null, or not an array:", nightForecastData);
            return "Error: Invalid night forecast data.";
        }
        // Résumé des prévisions de la nuit
        const summary = nightForecastData
            .map(({ hour, clouds_low, clouds_mid, clouds_high, humidity, temperature, dew_point_2m, windSpeed }) =>
                `At ${hour}h: ${clouds_low}% low-level, ${clouds_mid}% mid-level, and ${clouds_high}% high-level clouds.`
            )
            .join(" ");

        console.log(summary)
        //console.log('summary', summary)
        // Création du prompt avec sunset et sunrise inclus
        return `"Tonight's stargazing forecast: ${summary}. Share the best observation window briefly (in ${browserLanguage})."`;
    };

    const hasConsecutiveGoodHours = (hourlyData, threshold = 30, requiredConsecutiveHours = 2) => {
        let consecutiveGoodHours = 0;

        for (let i = 0; i < hourlyData.length; i++) {
            if (hourlyData[i].clouds <= threshold) {
                consecutiveGoodHours++;
                if (consecutiveGoodHours >= requiredConsecutiveHours) {
                    return true;
                }
            } else {
                consecutiveGoodHours = 0;
            }
        }

        return false;
    };


    const dayForecastData = forecastData.filter(({ dateTime }) =>
        dateTime >= sunsetTime && dateTime <= sunriseTime
    );
    const isObservable = hasConsecutiveGoodHours(dayForecastData);




    useEffect(() => {
        const currentTime = Date.now(); // Obtenez l'heure actuelle
        const storedStargazingDescription = localStorage.getItem('stargazingDescription');
        const storedLastFetchedTime = localStorage.getItem('lastFetchedTime');

        // Si la description existe et que moins de 1 heure s'est écoulée depuis le dernier appel
        if (storedStargazingDescription && storedLastFetchedTime && (currentTime - parseInt(storedLastFetchedTime, 10) <= 3600000)) {
            // Moins d'une heure, utiliser la description stockée
            setStargazingDescription(storedStargazingDescription);
            //console.log("Using cached stargazing description.");
            setIsLoading(false);
            return; // Ne pas effectuer l'appel à Gemini
        }

        // Ne pas générer de description si l'observation n'est pas possible
        if (!isObservable) {
            //console.log("Conditions are not observable. No Gemini description generated.");
            setStargazingDescription(t("Stargazing is not optimal tonight.")); // Texte alternatif
            setIsLoading(false);
            return;
        }

        if (!openMeteoData) return; // S'assurer que les données météo sont disponibles avant de continuer

        const fetchData = async () => {
            const prompt = generateObservationText(nightForecastData); // Créer le prompt pour Gemini
            setIsLoading(true);  // Start loading

            try {
                const result = await model.generateContent(prompt); // Appelez Gemini pour générer la description
                const newStargazingDescription = result.response.text();

                setStargazingDescription(newStargazingDescription); // Mettez à jour l'état avec la description générée
                setIsGenerated(true); // Set generated state to true when the content is ready

                //console.log("Generated new stargazing description:", newStargazingDescription);

                // Sauvegarder la nouvelle description dans localStorage avec l'heure de la récupération
                localStorage.setItem('stargazingDescription', newStargazingDescription);
                localStorage.setItem('lastFetchedTime', currentTime.toString()); // Sauvegarder le temps de la dernière récupération
                setIsLoading(false);
            } catch (error) {
                console.error("Error generating content:", error); // Gestion des erreurs
            }
        };

        fetchData(); // Effectuer l'appel à Gemini si nécessaire
    }, [openMeteoData, isObservable]); // Ajoutez `isObservable` comme dépendance



    // Get the extreme cloud cover if no good observation period is available
    const extremeCloudCover = useMemo(() => {
        if (nightForecastData.length === 0) return null;
        return Math.max(...nightForecastData.map(item => item.clouds));
    }, [nightForecastData]);

    const displayCloudCover = bestObservationInfo.lowestCloudCover ?? extremeCloudCover;

    const getCloudCoverBackground = (cloudCover) => {
        if (cloudCover >= 80) return `url(${cloud80})`;
        if (cloudCover >= 50) return `url(${cloud50})`;
        if (cloudCover >= 20) return `url(${cloud20})`;
        return '';
    };

    // Calculer une valeur représentative pour chaque mesure de la nuit
    const calculateNightlyAverage = (data, key) => {
        const values = data.map((item) => item[key]).filter((value) => value !== null);
        if (values.length === 0) return null;
        const sum = values.reduce((acc, value) => acc + value, 0);
        return Math.round((sum / values.length) * 10) / 10; // arrondi à une décimale
    };


    // Moyennes pour chaque mesure de la nuit
    const nightlyTemperature = Math.floor(calculateNightlyAverage(nightForecastData, "temperature"));
    const nightlyHumidity = Math.floor(calculateNightlyAverage(nightForecastData, "humidity"));
    const nightlyWindSpeed = Math.floor(calculateNightlyAverage(nightForecastData, "windSpeed"));
    const nightlydewpoint = Math.floor(calculateNightlyAverage(nightForecastData, "dew_point_2m"));
    const maxPrecipitationProbability = Math.max(
        ...nightForecastData.map(data => data.precipitation_probability)
    );


    const generateSeeingIndexForNight = (nightForecastData) => {
        let totalIndex = 0;
        let count = 0;

        // Calculer l'indice moyen pour chaque heure de la nuit
        nightForecastData.forEach(({ clouds, temperature, windSpeed, humidity, dew_point_2m }) => {
            const seeingIndex = calculateSeeingIndex(clouds, temperature, windSpeed, humidity, dew_point_2m);
            totalIndex += seeingIndex;
            count++;
        });

        // Retourner l'indice moyen arrondi
        return count > 0 ? Math.round(totalIndex / count) : 0;
    };

    // Direction du vent moyenne en utilisant une fonction de moyenne vectorielle
    const calculateWindDirectionAverage = (data) => {
        const windDirections = data.map((item) => item.windDirection).filter((value) => value !== null);
        if (windDirections.length === 0) return null;

        const x = windDirections.reduce((acc, direction) => acc + Math.cos((direction * Math.PI) / 180), 0);
        const y = windDirections.reduce((acc, direction) => acc + Math.sin((direction * Math.PI) / 180), 0);

        const averageDirection = Math.atan2(y, x) * (180 / Math.PI);
        return Math.round((averageDirection + 360) % 360); // arrondi en degrés entre 0 et 360
    };

    const convertWindDirectionToNSEW = (degrees) => {
        if (degrees === null || isNaN(degrees)) return 'N/A';

        const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
        const index = Math.floor((degrees + 22.5) / 45) % 8; // divise les 360° en 8 segments
        return directions[index];
    };

    const nightlyWindDirection = calculateWindDirectionAverage(nightForecastData);

    // Convertir la direction du vent en points cardinaux
    const windDirectionText = convertWindDirectionToNSEW(nightlyWindDirection);




    // Utilisez l'indice calculé
    const seeingIndex = generateSeeingIndexForNight(nightForecastData);




    return (
        <div>
            <div tabIndex="0">
                {!openMeteoData ? (
                    <div className='ContainerSkeleton'></div>
                ) : (
                    <>
                        {isObservable ? (
                            <div className="badgeGoodnight font-medium">
                                <img src={tickImage} alt="Good for stargazing" />
                                {isLoading ? (
                                    <p className='m-0'>Let me analyze...</p>
                                ) : (
                                    <p className='m-0'>{stargazingDescription}</p>
                                )}
                            </div>
                        ) : (
                            <div className="badgeBadnight">
                                <img src={nopImage} alt="Bad for stargazing" />
                                <p className='m-0'>{t('badforecast')}</p>
                            </div>
                        )}
                    </>
                )}
            </div>


            <div tabIndex="0" className='mt-3 ContainerTonightCloud bg-gray-900 p-3 rounded-t-md' style={{ backgroundImage: getCloudCoverBackground(displayCloudCover) }}>
                <div className='flex items-center gap-1 '>
                    <Cloud size={16} className='text-gray-500' />

                    <h2 className='text-gray-500' >{t('cloudcover')}</h2>
                </div>
                <div>
                    <h1 className='text-center' >{displayCloudCover} %</h1>
                </div>
            </div>
  
            <Divider className='bg-gray-800' />
            <CloudLevelInfo hourlyData={forecastData} />

            <Survey />
            
            <div className='grid grid-cols-2  md:grid-cols-3 lg:grid-cols-6 gap-3 mt-3' >
                <div tabindex="0" className='cursor-pointer  bg-gray-900 p-3 rounded-md flex flex-col gap-2' >
                    <div tabindex="0"  >
                        <Popover backdrop="opaque" >
                            <PopoverTrigger>
                                <div className='flex gap-1 items-center' >
                                    <Eye size={16} className='text-gray-500' />
                                    <h2 >Seeing</h2>

                                    <InfoCircle className='text-gray-500' size={18} />
                                </div>
                            </PopoverTrigger>
                            <PopoverContent className="max-w-64 purple-dark  p-4">
                                {t('infoseeing')}
                            </PopoverContent>
                        </Popover>

                    </div>
                    <div>
                        <h1>
                            {!openMeteoData ? (
                                <div className='ContainerSkeleton' style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <>
                                    {seeingIndex}
                                    <font style={{ color: 'var(--white)' }}>/5</font>
                                </>
                            )}
                        </h1>
                    </div>
                </div>



                <div tabindex="0" className='cursor-pointer bg-gray-900 p-3 rounded-md flex flex-col gap-2 ' >


                    <Popover backdrop="opaque" >
                        <PopoverTrigger>
                            <div tabindex="0" className='flex gap-1 items-center'>
                                <Wind size={16} className='text-gray-500' />
                                <h2 >{t('wind')}</h2>

                                <InfoCircle className='text-gray-500' size={18} />
                            </div>

                        </PopoverTrigger>
                        <PopoverContent className="max-w-64 purple-dark  p-4">
                            Wind at 10m.
                        </PopoverContent>
                    </Popover>





                    <div className='flex items-center gap-2' >
                        <div >
                            <h1 >
                                {!openMeteoData ? (
                                    <div className='ContainerSkeleton' style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                                ) : (
                                    <>
                                        {nightlyWindSpeed}
                                        <font style={{ color: 'var(--white)', fontSize: '20px' }}> {unitWind}</font>

                                    </>
                                )}
                            </h1>
                        </div>
                        <div>

                            {!openMeteoData ?
                                <div className='ContainerSkeleton' style={{ width: '60px', height: '14px', marginTop: '30px' }}></div> :
                                <div style={{ display: 'flex', alignItems: 'center', borderRadius: '10px' }} >
                                    <img src={arrow} style={{ transform: `rotate(${nightlyWindDirection + 180}deg)`, width: '22px' }} alt="wind direction" />
                                    <div> <p style={{ color: 'var(--white)' }} >{windDirectionText}</p> </div>
                                </div>
                            }

                        </div>


                    </div>

                </div>

                <div tabindex="0" className='bg-gray-900 p-3 rounded-md flex flex-col gap-2'  >
                    <div tabindex="0" className='flex gap-1 items-center'>
                        <Drop variant='Linear' className='text-gray-500' size={16} />

                        <h2>{t('humidity')}</h2>
                    </div>
                    <div>
                        <h1>
                            {!openMeteoData ? (
                                <div className='ContainerSkeleton' style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <>
                                    {nightlyHumidity}
                                    <font style={{ color: 'var(--white)', fontSize: '20px' }}> %</font>
                                </>
                            )}
                        </h1>
                    </div>
                </div>


                <div tabindex="0" className='bg-gray-900 p-3 rounded-md flex flex-col gap-2' >
                    <div tabindex="0" className='flex gap-1 items-center'>
                        <TbTemperature className='text-gray-500' size={16} />

                        <h2>{t('temperature')}</h2>
                    </div>
                    <div>
                        <h1>
                            {!openMeteoData ? (
                                <div className='ContainerSkeleton' style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <>
                                    {nightlyTemperature}
                                    <font style={{ color: 'var(--white)' }}> °</font>
                                </>
                            )}
                        </h1>
                    </div>
                </div>


                <div tabindex="0" className='bg-gray-900 p-3 rounded-md flex flex-col gap-2'  >
                    <div tabindex="0" className='flex gap-1 items-center'>
                        <MdDewPoint className='text-gray-500' size={16} />
                        <h2>{t('dewpoint')}</h2>
                    </div>
                    <div>
                        <h1>
                            {!openMeteoData ? (
                                <div className='ContainerSkeleton' style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <>
                                    {nightlydewpoint}
                                    <font style={{ color: 'var(--white)' }}> °</font>
                                </>
                            )}
                        </h1>
                    </div>
                </div>

                <div tabindex="0" className='bg-gray-900 p-3 rounded-md flex flex-col gap-2'>
                    <div tabindex="0" className='flex gap-1 items-start'>
                        <div>
                            <CloudDrizzle size={16} className='text-gray-500' />
                        </div>

                        <h2>{t('probabilityprecipitation')}</h2>
                    </div>
                    <div>
                        <h1>
                            {!openMeteoData ? (
                                <div className='ContainerSkeleton' style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <>
                                    {maxPrecipitationProbability}
                                    <font style={{ color: 'var(--white)' }}> %</font>
                                </>
                            )}
                        </h1>
                    </div>
                </div>


            </div>

            <div className="grid md:grid-cols-2 gap-3 mt-3">
                <LightPollutionMap />
                <MoonComponent />
            </div>



        </div >
    );
};

export default TonightComponent;
