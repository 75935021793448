import React, { useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';

const VersionCheck = () => {
  useEffect(() => {
    const versionDocRef = doc(firestore, 'system', 'update');

    const unsubscribe = onSnapshot(versionDocRef, (versionDocSnapshot) => {
      if (versionDocSnapshot.exists()) {
        const lastUpdate = versionDocSnapshot.data().last_update;

        if (lastUpdate) {
          const lastUpdateString = lastUpdate.toDate().toISOString();
          const storedUpdate = localStorage.getItem('last_update');

          if (storedUpdate !== lastUpdateString) {
            localStorage.setItem('last_update', lastUpdateString); // Synchronise localStorage
            window.location.reload();
          }
        }
      }
    });

    return () => unsubscribe(); // Clean up
  }, []);

  return null; // Pas besoin de rendre quoi que ce soit
};

export default VersionCheck;
