import React, { useState, useEffect } from 'react';
import { MoonPhase, Illumination, SearchRiseSet, Observer } from 'astronomy-engine';
import ArrowUp from '../assets/arrow_up.svg';
import ArrowDown from '../assets/arrow_down.svg'

import waning_gibbous from '../assets/moonphase/waning_gibbous.png';
import third_quarter from '../assets/moonphase/third_quarter.png';
import waxing_gibbous from '../assets/moonphase/waxing_gibbous.png';
import first_quarter from '../assets/moonphase/first_quarter.png';
import waxing_crescent from '../assets/moonphase/waxing_crescent.png';
import waning_crescent from '../assets/moonphase/waning_crescent.png';
import new_moon from '../assets/moonphase/new_moon.png';
import full_moon from '../assets/moonphase/full_moon.png'
import { useTranslation } from 'react-i18next';


const SolarSystemComponent = () => {

    const { t } = useTranslation();

    const [latitude, setLatitude] = useState(parseFloat(localStorage.getItem('latitude')) || 47);
    const [longitude, setLongitude] = useState(parseFloat(localStorage.getItem('longitude')) || -2.2);
    const celestialBodies = ['Moon', 'Mercury', 'Venus', 'Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune'];
    const [celestialData, setCelestialData] = useState({});

    useEffect(() => {
        const date = new Date();
        const observer = new Observer(latitude, longitude, 0);

        const directionRise = 1;
        const directionSet = -1;
        const dateStart = date;
        const limitDays = 7;
        const metersAboveGround = 0;

        celestialBodies.forEach(async (body) => {
            let bodyData = {};

            if (body === 'Moon') {
                const eclipticLongitude = MoonPhase(date);
                bodyData.phase = calculateMoonPhase(eclipticLongitude);
                const illuminationResult = Illumination(body, date);
                bodyData.illumination = illuminationResult || { phase_fraction: 0 };
            }

            const riseSetResult = await SearchRiseSet(body, observer, directionRise, dateStart, limitDays, metersAboveGround);
            bodyData.riseInfo = riseSetResult || {};

            const setSetResult = await SearchRiseSet(body, observer, directionSet, dateStart, limitDays, metersAboveGround);
            bodyData.setInfo = setSetResult || {};

            setCelestialData(prevData => ({ ...prevData, [body]: bodyData }));
        });
    }, [latitude, longitude]);



    const calculateMoonPhase = (eclipticLongitude) => {
        const calculateMoonPhaseName = (eclipticLongitude) => {
            const normalizedLongitude = (eclipticLongitude + 360) % 360;
            let moonPhaseKey;

            if (normalizedLongitude < 22.5 || normalizedLongitude > 337.5) {
                moonPhaseKey = 'moonPhase.newMoon';
            } else if (normalizedLongitude < 67.5) {
                moonPhaseKey = 'moonPhase.waxingCrescent';
            } else if (normalizedLongitude < 112.5) {
                moonPhaseKey = 'moonPhase.firstQuarter';
            } else if (normalizedLongitude < 157.5) {
                moonPhaseKey = 'moonPhase.waxingGibbous';
            } else if (normalizedLongitude < 202.5) {
                moonPhaseKey = 'moonPhase.fullMoon';
            } else if (normalizedLongitude < 247.5) {
                moonPhaseKey = 'moonPhase.waningGibbous';
            } else if (normalizedLongitude < 292.5) {
                moonPhaseKey = 'moonPhase.lastQuarter';
            } else {
                moonPhaseKey = 'moonPhase.waningCrescent';
            }

            return moonPhaseKey;
        };


        const getMoonPhaseImage = (moonPhaseKey) => {
            const moonPhaseToImageMap = {
                'moonPhase.newMoon': new_moon,
                'moonPhase.waxingCrescent': waxing_crescent,
                'moonPhase.firstQuarter': first_quarter,
                'moonPhase.waxingGibbous': waxing_gibbous,
                'moonPhase.fullMoon': full_moon,
                'moonPhase.waningGibbous': waning_gibbous,
                'moonPhase.lastQuarter': third_quarter,
                'moonPhase.waningCrescent': waning_crescent,
            };

            return moonPhaseToImageMap[moonPhaseKey];
        };


        let moonPhaseName = calculateMoonPhaseName(eclipticLongitude);
        let moonPhaseImage = getMoonPhaseImage(moonPhaseName);

        return {
            moonPhaseName: moonPhaseName,
            moonPhaseImage: moonPhaseImage,
        };
    };





    const isNextDay = (time) => {
        const currentDay = new Date();
        currentDay.setHours(0, 0, 0, 0);

        const nextDay = new Date(currentDay);
        nextDay.setDate(currentDay.getDate() + 1);

        const timeDate = new Date(time);
        return timeDate >= nextDay;
    };

    return (

        <div className='bg-gray-900 p-3 rounded-md' >
            <div className='flex items-center gap-1 '>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.40468 2C3.24241 2.80133 2 4.88362 2 7.32476C2 10.4591 4.54088 13 7.67524 13C10.1161 13 12.1988 11.7575 13 9.59532C12.3536 9.85531 11.6482 9.98816 10.9089 9.98816C7.81103 9.98816 5.01192 7.18905 5.01192 4.09116C5.01192 3.35174 5.14479 2.64643 5.40475 2.00008L5.40468 2Z" fill="var(--grey10)" />
                </svg>
                <h2 >{t('moon')}</h2>
            </div>


            <div className='flex items-center text-left gap-4' >
                <div>
                    {celestialData['Moon'] && (
                        <>
                            <img style={{ width: '70px' }} src={celestialData['Moon'].phase.moonPhaseImage} alt="Moon phase" />
                        </>
                    )}
                </div>


                <div className='flex gap-1 gap-6' >

                    {celestialData['Moon'] && (
                        <>
                            <div>
                                <p>
                                    {t('moonphasetitle')} <span style={{ color: 'var(--white)' }}>
                                        {t(celestialData['Moon'].phase.moonPhaseName)}
                                    </span>
                                </p>
                                <p>{t('illuminationtitle')} <span style={{ color: 'var(--white)' }}>{(celestialData['Moon'].illumination.phase_fraction * 100).toFixed(1)}%</span></p>
                            </div>


                            <div>

                                {celestialData['Moon'].riseInfo.date && (
                                    <div className='flex' >
                                        <img src={ArrowUp} alt="Moonrise" />

                                        <p >{new Date(celestialData['Moon'].riseInfo.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{isNextDay(celestialData['Moon'].riseInfo.date) && '*'}</p>
                                    </div>
                                )}
                                {celestialData['Moon'].setInfo.date && (
                                    <div className='flex' >
                                        <img src={ArrowDown} alt="Moonset" />
                                        <p  >{new Date(celestialData['Moon'].setInfo.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{isNextDay(celestialData['Moon'].setInfo.date) && '*'}</p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>

        </div>



    );
};



export default SolarSystemComponent;
