import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Divider, Accordion, AccordionItem } from "@nextui-org/react";
import { Chart1 } from "iconsax-react";

const CloudCoverMeteogram = ({ hourlyData }) => {
    const { t } = useTranslation();

    const currentHour = new Date().getHours();

    console.log(hourlyData);

    // Filtrer les données horaires pour obtenir les 24 heures suivantes
    const getFilteredHourlyData = () => {
        const currentDateTime = new Date();
        return hourlyData.filter(({ dateTime }) => {
            const dataDateTime = new Date(dateTime);
            return dataDateTime >= currentDateTime; // Garder les données futures
        }).slice(0, 24);
    };

    const filteredHourlyData = getFilteredHourlyData();

    console.log('filteredHourlyData', filteredHourlyData);

    // Calculer les heures et les ajuster pour l'affichage
    const hours = filteredHourlyData.map(({ hour }, index) => {
        const dataHour = typeof hour === "string" ? parseInt(hour.split(":")[0], 10) : hour;
        // Ajuster l'heure en fonction de l'heure actuelle
        const adjustedHour = (currentHour + index) % 24;
        return `${adjustedHour < 10 ? '0' : ''}${adjustedHour}h`; // Format de l'heure avec deux chiffres
    });

    const lowClouds = filteredHourlyData.map(({ clouds_low }) => clouds_low);
    const midClouds = filteredHourlyData.map(({ clouds_mid }) => clouds_mid);
    const highClouds = filteredHourlyData.map(({ clouds_high }) => clouds_high);

    // Données de couverture nuageuse sans interpolation
    const cloudData = [
        highClouds,
        midClouds,
        lowClouds,
        Array(hours.length).fill(0), // Ground layer
    ];

    // Calculer la couleur et taille des particules pour chaque cellule
    const getCellColor = (value, humidity) => {
        const opacity = value / 100;
        const particleSize = humidity > 80 ? 10 : 6;
        return {
            color: `rgba(255, 255, 255, ${opacity})`,
            particleSize,
        };
    };

    // Générer les particules de pluie au sol si probabilité de précipitation élevée
    const generateRainParticles = (precipitationProbability) => {
        if (precipitationProbability > 60) {
            return Array.from({ length: 6 }, (_, index) => {
                const animationDelay = Math.random() * 2;
                const animationDuration = 1 + Math.random() * 1.5;
                const leftOffset = Math.random() * 20 - 10;

                return (
                    <div
                        key={index}
                        style={{
                            position: "absolute",
                            top: `${Math.random() * 100}%`,
                            left: `${Math.random() * 100}%`,
                            width: "4px",
                            height: "4px",
                            backgroundColor: "rgb(3, 105, 161)",
                            borderRadius: "50%",
                            opacity: 0.8,
                            pointerEvents: "none",
                            animation: `rain-fall ${animationDuration}s linear infinite`,
                            animationDelay: `${animationDelay}s`,
                            left: `calc(50% + ${leftOffset}px)`,
                        }}
                    />
                );
            });
        }
        return [];
    };

    const cellSize = 40;

    return (
        <Accordion>
            <AccordionItem isCompact={true} className="bg-gray-900 px-3 rounded-b-md" key="1" aria-label="Meteorogram cloud cover" title={
                <div className="flex gap-1 items-center">
                    <Chart1 size={16} className='text-gray-500' />
                    <h2 className="font-regular text-sm">Meteogram</h2>
                </div>
            }>

                <div className="pb-4 rounded-b-md">
                    <p className="text-left pb-2 text-sm">Displays cloud cover changes over time, layer by layer.</p>
                    <div className="overflow-auto bg-gray-950 rounded-md p-2" style={{ display: "grid", gridTemplateRows: `repeat(4, ${cellSize}px)` }}>
                        {cloudData.map((cloudLayer, layerIndex) => (
                            <div key={layerIndex} className="flex">

                                <div className="flex text-sm text-gray-500 text-right items-center w-12 justify-end">
                                    {["High", "Mid", "Low", "Ground"][layerIndex]}
                                </div>

                                <Divider className="mx-2" orientation="vertical" />

                                {cloudLayer.map((value, index) => {
                                    if (layerIndex === 3) { // Ground layer
                                        const precipitationProbability = filteredHourlyData[index].precipitation_probability;
                                        const rainParticles = generateRainParticles(precipitationProbability);
                                        return (
                                            <div key={index} className="rounded-full" style={{ position: "relative", width: cellSize, height: cellSize }}>
                                                {rainParticles}
                                            </div>
                                        );
                                    }

                                    const { color, particleSize } = getCellColor(value, filteredHourlyData[index].humidity);
                                    const particles = Array.from({ length: Math.round(value / 3) }, (_, i) => (
                                        <div
                                            key={`${index}-${i}`}
                                            style={{
                                                position: "absolute",
                                                top: `${Math.random() * 100}%`,
                                                left: `${Math.random() * 100}%`,
                                                width: `${particleSize}px`,
                                                height: `${particleSize}px`,
                                                backgroundColor: color,
                                                borderRadius: "30%",
                                                pointerEvents: "none",
                                            }}
                                        />
                                    ));

                                    return (
                                        <div key={index} style={{ position: "relative", width: cellSize, height: cellSize }}>
                                            {particles}
                                        </div>
                                    );
                                })}
                            </div>
                        ))}

                        <div id="ground" className="h-0.5 bg-gray-700" />

                        <div className="mt-2 mx-16" style={{ display: "grid", gridTemplateColumns: `repeat(${hours.length}, ${cellSize}px)` }}>
                            {hours.map((hour, index) => (
                                <div
                                    key={index}
                                    className="text-sm font-medium text-gray-500"
                                >
                                    {hour}
                                </div>
                            ))}
                        </div>

                    </div>

                </div>
            </AccordionItem>

        </Accordion>
    );
};

CloudCoverMeteogram.propTypes = {
    hourlyData: PropTypes.arrayOf(PropTypes.shape({
        hour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        clouds_low: PropTypes.number.isRequired,
        clouds_mid: PropTypes.number.isRequired,
        clouds_high: PropTypes.number.isRequired,
        temperature: PropTypes.number.isRequired,
        humidity: PropTypes.number.isRequired,
        precipitation_probability: PropTypes.number.isRequired,
    })).isRequired,
};

export default CloudCoverMeteogram;