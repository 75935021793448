import React, { useEffect, useState } from 'react';
import { getFirestore, collection, onSnapshot, query, where, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { app } from '../firebase'; // Importez votre configuration Firebase
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";

const Admin = () => {
    const [activeUsersCount, setActiveUsersCount] = useState(0);
    const [amateurUsersCount, setAmateurUsersCount] = useState(0);
    const [professionalUsersCount, setProfessionalUsersCount] = useState(0);
    const [noUsersCount, setnoUsersCount] = useState(0);
    const [timeScale, setTimeScale] = useState(24 * 60 * 60 * 1000); // Échelle par défaut : 24 heures
    const [selectedLabel, setSelectedLabel] = useState("Dernières 24 heures"); // Étiquette par défaut
    const [isAdmin, setIsAdmin] = useState(false); // Vérification du rôle de l'utilisateur
    const firestore = getFirestore(app);
    const auth = getAuth(app); // Authentification Firebase
   
    

    useEffect(() => {
        // Vérifier le rôle de l'utilisateur
        const user = auth.currentUser;
        if (user) {
            const userRef = doc(firestore, "users", user.uid); // Récupérer le document de l'utilisateur
            getDoc(userRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    if (userData.role === "admin") {
                        setIsAdmin(true); // L'utilisateur est un administrateur
                    }
                }
            });
        }
    }, [auth, firestore]);

    useEffect(() => {
        if (!isAdmin) return; // Ne pas continuer si l'utilisateur n'est pas administrateur

        const recentActivityThreshold = new Date(Date.now() - timeScale);

        const usersCollection = collection(firestore, "users");
        const q = query(usersCollection, where("lastActive", ">", recentActivityThreshold));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            setActiveUsersCount(snapshot.size);
        });

        return () => unsubscribe();
    }, [firestore, timeScale, isAdmin]);

    useEffect(() => {
        if (!isAdmin) return; // Ne pas continuer si l'utilisateur n'est pas administrateur

        const usersCollection = collection(firestore, "users");

        // Query to count amateur users
        const amateurQuery = query(usersCollection, where("userType", "==", "amateur"));
        const professionalQuery = query(usersCollection, where("userType", "==", "professional"));

        const noQuery = query(usersCollection, where("userType", "==", "no response"));
        // Subscribe to amateur users
        const unsubscribeAmateurs = onSnapshot(amateurQuery, (snapshot) => {
            setAmateurUsersCount(snapshot.size);
        });

        // Subscribe to professional users
        const unsubscribeProfessionals = onSnapshot(professionalQuery, (snapshot) => {
            setProfessionalUsersCount(snapshot.size);
        });

        const unsubscribeNo = onSnapshot(noQuery, (snapshot) => {
            setnoUsersCount(snapshot.size);
        });


        return () => {
            unsubscribeNo();
            unsubscribeAmateurs();
            unsubscribeProfessionals();
        };
    }, [firestore, isAdmin]);

    const handleTimeScaleChange = (key) => {
        const timeScales = {
            "24h": { ms: 24 * 60 * 60 * 1000, label: "Dernières 24 heures" },
            "1w": { ms: 7 * 24 * 60 * 60 * 1000, label: "Dernière semaine" },
            "1m": { ms: 30 * 24 * 60 * 60 * 1000, label: "Dernier mois" },
            "3m": { ms: 3 * 30 * 24 * 60 * 60 * 1000, label: "Derniers 3 mois" },
            "6m": { ms: 6 * 30 * 24 * 60 * 60 * 1000, label: "Derniers 6 mois" },
        };

        if (timeScales[key]) {
            setTimeScale(timeScales[key].ms);
            setSelectedLabel(timeScales[key].label); // Mettre à jour l'étiquette
        }
    };

    // Si l'utilisateur n'est pas administrateur, ne rien afficher
    if (!isAdmin) {
        return <div className='min-h-screen'>You do not have access to the admin panel.</div>;
    }

    return (
        <div className="pt-8 min-h-screen flex flex-col gap-4">

            <div>
                <div className="flex justify-between items-center my-2">
                    <h1>Admin Panel</h1>

                    <Dropdown className="dark">
                        <DropdownTrigger>
                            <Button>
                                {selectedLabel} {/* Affiche l'étiquette actuelle */}
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                            aria-label="Changer l'échelle temporelle"
                            onAction={handleTimeScaleChange}
                        >
                            <DropdownItem key="24h">Dernières 24 heures</DropdownItem>
                            <DropdownItem key="1w">Dernière semaine</DropdownItem>
                            <DropdownItem key="1m">Dernier mois</DropdownItem>
                            <DropdownItem key="3m">Derniers 3 mois</DropdownItem>
                            <DropdownItem key="6m">Derniers 6 mois</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>


                <div className="text-left bg-gray-900 p-4 rounded-md">
                    <p className="text-3xl font-bold text-primary-800">{activeUsersCount}</p>
                    <p className="text-gray-400">Active registered users</p>
                </div>
            </div>



            <div>
                <h1 className='my-2'>Sondage</h1>
                <div className="grid grid-cols-2  md:grid-cols-3 gap-2">
                    {/* Comptage par type d'utilisateur */}
                    <div className="text-left bg-gray-900 p-4 rounded-md">
                        <p className="text-3xl font-bold text-primary-800">{amateurUsersCount}</p>
                        <p className="text-gray-400">Amateur Astronomers</p>
                    </div>

                    <div className="text-left bg-gray-900 p-4 rounded-md">
                        <p className="text-3xl font-bold text-primary-800">{professionalUsersCount}</p>
                        <p className="text-gray-400">Professional Astronomers</p>
                    </div>

                    <div className="text-left bg-gray-900 p-4 rounded-md">
                        <p className="text-3xl font-bold text-primary-800">{noUsersCount}</p>
                        <p className="text-gray-400">No response</p>
                    </div>
                </div>
            </div>



        </div>
    );
};

export default Admin;
