import React, { useState, useEffect } from 'react';
import { getFirestore, updateDoc, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app } from '../firebase'; // Assurez-vous de configurer votre Firebase
import { Button } from "@nextui-org/react";
import { Add } from "iconsax-react";

const Survey = () => {
    const [surveyCompleted, setSurveyCompleted] = useState(true); // Par défaut, on ne montre rien
    const firestore = getFirestore(app);
    const auth = getAuth(app); // Authentification Firebase

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            const userRef = doc(firestore, "users", user.uid); // Référence du document de l'utilisateur
            getDoc(userRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    if (userData.userType) {
                        setSurveyCompleted(true); // Si userType existe, ne pas afficher le sondage
                    } else {
                        setSurveyCompleted(false); // Sinon, afficher le sondage
                    }
                }
            }).catch((error) => {
                console.error("Erreur lors de la récupération des données utilisateur", error);
            });
        }
    }, [auth, firestore]);

    const handleAnswer = async (answer) => {
        const user = auth.currentUser;
        if (user) {
            try {
                const userRef = doc(firestore, "users", user.uid); // Référence du document de l'utilisateur
                const userSnapshot = await getDoc(userRef);
                const userData = userSnapshot.data();

                if (!userData.userType) {
                    // Si aucune réponse n'est enregistrée, on met à jour avec la nouvelle réponse
                    await updateDoc(userRef, {
                        userType: answer,  // Enregistrer la réponse dans le document utilisateur
                    });
                    setSurveyCompleted(true); // Marquer le sondage comme complété
                }
            } catch (error) {
                console.error("Erreur lors de la mise à jour des données de l'utilisateur", error);
            }
        }
    };

    const handleClose = async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                const userRef = doc(firestore, "users", user.uid); // Référence du document de l'utilisateur
                await updateDoc(userRef, {
                    userType: 'no response',  // Réinitialiser userType à null
                });
                setSurveyCompleted(true); // Fermer le sondage
            } catch (error) {
                console.error("Erreur lors de la mise à jour des données de l'utilisateur", error);
            }
        }
    };

    // Si le sondage est déjà complété (userType existe), on ne l'affiche plus
    if (surveyCompleted) {
        return null; // Par défaut, rien n'est affiché
    }

    return (
        <div className='text-left ContainerTonightCloud bg-indigo-950	 p-3 rounded-md mt-2 flex flex-col '>
            <div className='flex justify-between' >
                <p className='text-sm' >Do you use Ouranos as an amateur or professional astronomer?</p>

                <button
                    isIconOnly
                    className='rotate-45'
                    onClick={handleClose}
                >
                    <Add />
                </button>
            </div>


            <div className="flex flex-col md:flex-row gap-2 w-full">
                <Button
                    variant="flat"
                    size="sm"
                    className="w-full md:w-1/2 bg-indigo-900	"
                    onPress={() => handleAnswer('amateur')}
                >
                    Amateur Astronomer
                </Button>
                <Button
                    variant="flat"
                    size="sm"
                    className="w-full md:w-1/2 bg-indigo-900	"
                    onPress={() => handleAnswer('professional')}
                >
                    Professional Astronomer
                </Button>
            </div>

        </div>
    );
};

export default Survey;
