// Tab2.js
import React from 'react';


import DailyComponent from './DailyComponent';
import DailyComponentFree from './DailyComponentFree';
import ApiComponent from './ApiComponent';
import LocationComponent from './LocationComponent';
import StripeProducts from './StripeProducts';
import { useTranslation } from 'react-i18next';
import { useLocationData } from './GlobalContext';

const Tab3 = () => {
  const { t } = useTranslation();
  const { user, subscription, userData } = useLocationData();

  console.log(subscription)
  return (
    <div className='min-h-screen' >


      <LocationComponent />

  
      <ApiComponent>
        {({ openMeteoData }) => (
          subscription === 1 ? (
            <DailyComponent openMeteoData={openMeteoData} />
          ) : (
            <>
            
              <DailyComponentFree user={user} openMeteoData={openMeteoData} />
         
      
            </>
          )
        )}
      </ApiComponent>



    </div>
  );
};

export default Tab3;