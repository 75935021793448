import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Observer, SearchRiseSet, Equator, Horizon } from 'astronomy-engine';
import { Accordion, AccordionItem, Button } from '@nextui-org/react';

import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, ReferenceArea, CartesianGrid } from 'recharts';
import JupiterIcon from '../assets/jupiter.svg';
import VenusIcon from '../assets/venus.svg';
import UranusIcon from '../assets/uranus.svg';
import MarsIcon from '../assets/mars.svg';
import SaturnIcon from '../assets/saturn.svg';
import NeptuneIcon from '../assets/neptune.svg';
import MercuryIcon from '../assets/mercury.svg'
import { FiSunset, FiSunrise } from "react-icons/fi";
import { ArrowUp, ArrowDown } from "iconsax-react";
import { useLocationData } from './GlobalContext';
import { Map1, Lock, Add, InfoCircle } from "iconsax-react";
import PremiumNotification from './PremiumNotification';

const SolarSystemComponent = () => {
    const navigate = useNavigate(); // Hook pour la navigation

    const { subscription, location } = useLocationData();
    const celestialBodies = ['Mercury', 'Venus', 'Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune'];  // Ajouter le Soleil à la liste des corps célestes
    const [celestialData, setCelestialData] = useState({});
    const [coordinates, setCoordinates] = useState({
        latitude: parseFloat(localStorage.getItem('latitude')) || 47,
        longitude: parseFloat(localStorage.getItem('longitude')) || -2.2
    });
    const [sunData, setSunData] = useState(null);



    useEffect(() => {
        const date = new Date();
        const observer = new Observer(coordinates.latitude, coordinates.longitude, 0);
        const limitDays = 1;

        const fetchCelestialData = async (body) => {
            const bodyData = { times: [], altitudes: [], riseTime: null, setTime: null };
            const riseSetResult = await SearchRiseSet(body, observer, 1, date, limitDays, 0);
            const setSetResult = await SearchRiseSet(body, observer, -1, date, limitDays, 0);

            bodyData.riseTime = riseSetResult?.date ? new Date(riseSetResult.date) : null;
            bodyData.setTime = setSetResult?.date ? new Date(setSetResult.date) : null;

            const totalMinutes = 24 * 60;
            const now = new Date();
            const nowInMinutes = now.getHours() * 60 + now.getMinutes();

            for (let i = nowInMinutes; i < totalMinutes + nowInMinutes; i++) {
                const currentTime = new Date(now);
                currentTime.setMinutes(currentTime.getMinutes() + (i - nowInMinutes)); // Avance minute par minute
                const { ra, dec } = Equator(body, currentTime, observer, true, true);
                const { altitude } = Horizon(currentTime, observer, ra, dec, 'normal');
                const formattedTime = `${currentTime.getHours().toString().padStart(2, '0')}:${currentTime.getMinutes().toString().padStart(2, '0')}`;
                bodyData.times.push(formattedTime);
                bodyData.altitudes.push(altitude);
            }

            setCelestialData((prevData) => ({
                ...prevData,
                [body]: bodyData,
            }));

            if (body === 'Sun') {
                setSunData(bodyData);
            }
        };

        fetchCelestialData('Sun');
        celestialBodies.forEach(fetchCelestialData);
    }, [coordinates.latitude, coordinates.longitude]);



    const formatDataForChart = (bodyData) => {
        return bodyData.times.map((time, index) => {
            const [hour, minute] = time.split(':');
            const timeInMinutes = parseInt(hour) * 60 + parseInt(minute);
            return { time: timeInMinutes, altitude: bodyData.altitudes[index] };
        });
    };


    // Logique pour afficher les données du Soleil
    useEffect(() => {
        if (sunData) {
            console.log('Données du Soleil:', sunData);
        }
    }, [sunData]);

    const getAltitudeDomain = (bodyData) => {
        const minAltitude = Math.min(...bodyData.altitudes);
        const maxAltitude = Math.max(...bodyData.altitudes);
        const margin = 10;
        const maxRange = Math.max(Math.abs(minAltitude), Math.abs(maxAltitude)) + margin;
        return [-maxRange, maxRange];
    };

    const formatYAxisTicks = (tick) => Math.round(tick);


    const formatXAxisTicks = (tick) => {
        const nowInMinutes = new Date().getHours() * 60 + new Date().getMinutes();

        // Vérifiez si le tick correspond à l'heure actuelle
        if (tick === nowInMinutes) {
            return 'Now';
        }

        // Format normal pour les autres ticks
        const hours = Math.floor(tick / 60);
        const minutes = tick % 60;
        return `${hours.toString().padStart(2, '0')}h`;
    };


    const getRiseSetPosition = (time) => {
        if (!time) return null;

        // Si time est une chaîne, la convertir en Date
        const date = (typeof time === "string") ? new Date(time) : time;

        // Vérification si l'objet est bien un Date valide
        if (!(date instanceof Date) || isNaN(date)) {
            console.error("Invalid Date:", time);
            return null;
        }

        const minutes = date.getHours() * 60 + date.getMinutes();
        return minutes;
    };

    const getMaxAltitudePosition = (bodyData) => {
        const maxAltitude = Math.max(...bodyData.altitudes);
        const maxAltitudeIndex = bodyData.altitudes.indexOf(maxAltitude);
        return getRiseSetPosition(bodyData.times[maxAltitudeIndex]);
    };

    const CustomTooltip = ({ payload, label, active }) => {
        if (active && payload && payload.length) {
            const { time, altitude } = payload[0].payload; // Récupérer les données de l'élément sélectionné

            // Conversion en heures et minutes depuis 'time' en minutes
            const hours = Math.floor(time / 60);
            const minutes = time % 60;
            const formattedTime = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`; // Format HH:mm

            return (
                <div className='text-left bg-gray-800 p-2 flex flex-col gap-2 rounded-md' >
                    <p className="text-gray-400">{formattedTime}</p>
                    <p className='text-lg' >Altitude: <strong>{Math.round(altitude)}°</strong></p>
                </div>
            );
        }

        return null; // Si inactive, ne rien afficher
    };

    const planetImages = {
        Jupiter: JupiterIcon,
        Venus: VenusIcon,
        Uranus: UranusIcon,
        Mars: MarsIcon,
        Saturn: SaturnIcon,
        Neptune: NeptuneIcon,
        Mercury: MercuryIcon,
    };

    const getRiseSetTimeString = (time) => {
        if (!time) return 'N/A';

        const timeDate = new Date(time);
        const now = new Date();

        // Vérifiez si la date est aujourd'hui
        const isToday =
            timeDate.getDate() === now.getDate() &&
            timeDate.getMonth() === now.getMonth() &&
            timeDate.getFullYear() === now.getFullYear();

        if (isToday) {
            // Si c'est aujourd'hui, afficher uniquement l'heure
            return timeDate.toLocaleTimeString(location.language, { hour: '2-digit', minute: '2-digit' });
        }

        // Sinon, afficher la date complète
        return timeDate.toLocaleString(location.language, { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    };




    const getClosestTimeString = (riseTime, setTime) => {
        if (!riseTime && !setTime) return 'N/A';

        const rise = new Date(riseTime);
        const set = new Date(setTime);

        // Si riseTime est plus proche de maintenant
        if (rise && (!set || rise < set)) {
            return (
                <div className="bg-gray-800 p-1 px-2 flex items-center gap-1 rounded-md text-gray-300">
                    <ArrowUp size={16} />
                    <span>{getRiseSetTimeString(rise)}</span>
                </div>
            );
        }

        // Sinon afficher setTime
        return (
            <div className="bg-gray-800 p-1 px-2 flex items-center gap-1 rounded-md text-gray-300">
                <ArrowDown size={16} />
                <span>{getRiseSetTimeString(set)}</span>
            </div>
        );
    };




    const isCelestialBodyVisible = (bodyData) => {
        if (!bodyData?.altitudes?.length) return false;

        const nowMinutes = new Date().getHours() * 60 + new Date().getMinutes();  // Minutes depuis minuit

        const currentTimeIndex = bodyData.times.findIndex((time) => {
            const [hour, minute] = time.split(':');
            return (parseInt(hour) * 60 + parseInt(minute)) === nowMinutes;
        });

        return currentTimeIndex !== -1 && bodyData.altitudes[currentTimeIndex] > 0;
    };




    return (

        <div>

            {celestialBodies.map((body) => (

                <div>
                    <Accordion  >
                        <AccordionItem className='my-2 px-4 bg-gray-900 rounded-md' key={body}
                            title={
                                <div className="flex items-center gap-2">
                                    <img
                                        src={planetImages[body]} // Utiliser l'image en fonction du nom de la planète
                                        alt={body}
                                        className="w-8 h-8"
                                    />
                                    <h1 className='text-lg w-20' >{body}</h1>
                                    <span className={`text-sm ${isCelestialBodyVisible(celestialData[body]) ? 'bg-emerald-400/10 text-emerald-400 p-1 px-2 rounded-md' : 'bg-red-400/10 text-red-400 p-1 px-2 rounded-md'}`}>
                                        {isCelestialBodyVisible(celestialData[body]) ? 'Above Horizon' : 'Below Horizon'}
                                    </span>
                                    <span className="text-sm">
                                        {celestialData[body]?.riseTime || celestialData[body]?.setTime
                                            ? getClosestTimeString(celestialData[body]?.riseTime, celestialData[body]?.setTime)
                                            : 'Loading...'}
                                    </span>
                                </div>
                            }>

                            <div key={body} >

                                {subscription === 1 || body === 'Mercury' ? ( // Autorise l'affichage uniquement pour Mercure ou avec abonnement


                                    celestialData[body]?.times?.length > 0 ? (
                                        <ResponsiveContainer width="100%" height={200}>
                                            <LineChart
                                                data={formatDataForChart(celestialData[body])}
                                                style={{
                                                    filter: subscription !== 1 && body !== 'Mercury' ? 'blur(8px)' : 'none', // Applique le flou sauf pour Mercure
                                                    pointerEvents: subscription !== 1 && body !== 'Mercury' ? 'none' : 'auto' // Désactive les interactions sauf pour Mercure
                                                }}
                                            >
                                                <CartesianGrid stroke="#1e293b" />

                                                <XAxis
                                                    dataKey="time"
                                                    interval={180}
                                                    tickFormatter={formatXAxisTicks}
                                                    domain={['auto', 'dataMax']}  // Ajuste le domaine pour commencer à l'heure actuelle
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    textAnchor='start'
                                                />

                                                <YAxis
                                                    domain={getAltitudeDomain(celestialData[body])}
                                                    tickFormatter={formatYAxisTicks}
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    hide
                                                />

                                                <Tooltip
                                                    content={<CustomTooltip />}
                                                    cursor={{ stroke: '#94a3b8', strokeWidth: 2, strokeDasharray: '5 5' }}
                                                />

                                                <ReferenceLine y={0} stroke="#64748b" />

                                            
                                                <ReferenceArea
                                                    x1={getRiseSetPosition(sunData.riseTime)}  // Position de départ à 12h (midi)
                                                    x2={getRiseSetPosition(new Date().setHours(12, 0, 0, 0))}  // Position du lever du Soleil
                                                    opacity={1}
                                                    fill="#232C53" // Couleur pour la zone avant le lever du Soleil
                                                />

                                                <ReferenceArea
                                                    y1={-74}  // Position de départ à 12h (midi)
                                                    y2={0}  // Position du lever du Soleil
                                                    opacity={1}
                                                    fill="#374151" // Couleur pour la zone avant le lever du Soleil
                                                />

                                                <Line dataKey="altitude" stroke="white" strokeWidth={4} dot={false} activeDot={{ r: 8 }} />

                                                {/* Lignes de référence pour rise et set */}
                                                {celestialData[body] && celestialData[body].riseTime && celestialData[body].setTime ? (
                                                    <>
                                                        <ReferenceLine
                                                            x={getRiseSetPosition(celestialData[body].riseTime)} // Position de riseTime
                                                            stroke="#34d399"
                                                            strokeWidth={2}
                                                            opacity={1}
                                                            label={({ viewBox }) => {
                                                                const { x, y } = viewBox;
                                                                const riseTime = celestialData[body].riseTime;

                                                                if (riseTime) {
                                                                    const hours = riseTime.getHours();
                                                                    const minutes = riseTime.getMinutes();
                                                                    const formattedTime = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;

                                                                    return (
                                                                        <>
                                                                            <text
                                                                                x={x + 6}
                                                                                y={y + 126} // Position de "Rise"
                                                                                fill="#34d399"
                                                                                fontSize="14"
                                                                                opacity={0.6}
                                                                            >
                                                                                {body} Rise
                                                                            </text>
                                                                            <text
                                                                                x={x + 6}
                                                                                y={y + 144} // Position du formattedTime
                                                                                fill="#34d399"
                                                                                fontSize="16"
                                                                                fontWeight={500}
                                                                            >
                                                                                {formattedTime}
                                                                            </text>
                                                                        </>
                                                                    );
                                                                }

                                                                return null; // Si riseTime est null, ne rien afficher
                                                            }}
                                                        />

                                                        {celestialData[body].altitudes.length > 0 && (
                                                            <ReferenceLine
                                                                x={getMaxAltitudePosition(celestialData[body])}
                                                                stroke="#f97316"
                                                                label="Meridian"
                                                                strokeDasharray="5 5"
                                                            />
                                                        )}

                                                        <ReferenceLine
                                                            x={getRiseSetPosition(celestialData[body].setTime)} // Position de riseTime
                                                            stroke="#f87171"
                                                            strokeWidth={2}
                                                            opacity={1}
                                                            label={({ viewBox }) => {
                                                                const { x, y } = viewBox;
                                                                const setTime = celestialData[body].setTime;

                                                                if (setTime) {
                                                                    const hours = setTime.getHours();
                                                                    const minutes = setTime.getMinutes();
                                                                    const formattedTime = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;

                                                                    return (
                                                                        <>
                                                                            <text
                                                                                x={x + 6}
                                                                                y={y + 126} // Position de "Rise"
                                                                                fill="#f87171"
                                                                                fontSize="14"
                                                                                opacity={0.6}
                                                                            >
                                                                                {body} Set
                                                                            </text>
                                                                            <text
                                                                                x={x + 6}
                                                                                y={y + 144} // Position du formattedTime
                                                                                fill="#f87171"
                                                                                fontSize="16"
                                                                                fontWeight={500}
                                                                            >
                                                                                {formattedTime}
                                                                            </text>
                                                                        </>
                                                                    );
                                                                }

                                                                return null; // Si riseTime est null, ne rien afficher
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <div>Loading celestial data...</div>
                                                )}

                                                {/* Ligne du lever du Soleil */}
                                                {sunData?.riseTime && (
                                                    <ReferenceLine
                                                        x={getRiseSetPosition(sunData.riseTime)}
                                                        stroke="rgb(255, 165, 0, 0)"
                                                        label={({ viewBox }) => (
                                                            <foreignObject x={viewBox.x - 11} y={viewBox.y + 170} width="22" height="18">
                                                                <FiSunrise size={16} className="text-amber-200" />
                                                            </foreignObject>
                                                        )}
                                                    />
                                                )}

                                                {/* Ligne du coucher du Soleil */}
                                                {sunData?.setTime && (
                                                    <ReferenceLine
                                                        x={getRiseSetPosition(sunData.setTime)}
                                                        stroke="rgb(255, 165, 0, 0)"
                                                        label={({ viewBox }) => (
                                                            <foreignObject x={viewBox.x - 11} y={viewBox.y + 170} width="22" height="18" >
                                                                <FiSunset size={16} className="text-amber-200" />
                                                            </foreignObject>
                                                        )}
                                                    />
                                                )}
                                            </LineChart>
                                        </ResponsiveContainer>
                                    ) : (
                                        <div>Chargement des données célestes...</div>
                                    )
                                ) : (

                                    <PremiumNotification />

                                )}
                            </div>


                        </AccordionItem>
                    </Accordion>
                </div>
            ))
            }
        </div >



    );
};

export default SolarSystemComponent;
